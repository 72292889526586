@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300);
body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100%;
}

/* to use, font-family: 'Montserrat', sans-serif; */
@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat"),
    url(/static/media/Montserrat-Regular.ee653992.ttf) format("truetype");
}
.App {
  font-family: 'Montserrat';
  position: relative;
  /* Consider removing this if it's causing issues */
  height: 100%;
}

.navbar {
    display: contents;
    position: relative;
}

.top-bar {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 4vw 0 1vw;
    height: 11vh;
    width: 100%;
    background-color: #CDE1D6;
    z-index: 10;
}

.top-bar .mobile-menu {
    display: none;
}

.top-bar .left-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.top-bar .left-container .logo {
    width: 14rem;
    background-size: contain;
    transform: translateY(-0.5rem);
}

.top-bar .left-container .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 0 1rem;
    border-radius: 100px;
    width: 20vw;
    margin: 0 1rem;
}

.top-bar .left-container .search img {
    height: 1.25rem;
    background-size: contain;
    margin-right: 0.5rem;
}

.top-bar .left-container .search input {
    font-size: 0.9rem;
    color: black;
    font-family: 'Montserrat', sans-serif;
}

.top-bar .left-container .search p {
    font-size: 0.9rem;
    color: #B5B5B5;
    font-family: 'Montserrat', sans-serif;
}

.top-bar .left-container .filter {
    width: 1.5rem;
    background-size: contain;
    margin-left: 16vw;
}

.nav-elements {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-direction: row;
    list-style: none;
    margin-left: 1rem;
    position: relative;
}

.top-bar ul li {
    list-style: none;
    margin-left: 1rem;
}

.top-bar ul .arrow-container {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin-left: 1rem;
}

.top-bar ul .arrow-container img {
    margin-left: 0.3rem;
    position: relative;
}

.top-bar ul li a {
    color: black;
    text-decoration: none;
    font-size: 17px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.side-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 11vh;
    left: 0;
    height: 100%;
    width: 9rem;
    padding-top: 2rem;
    background-color: #213546;
    z-index: 5;
    border: none;
}

.navbar__img {
    width: 3rem;
    margin: 1rem 0 0 0;
}

.sidebar-item p {
    color: white;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;

}

.sidebar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem 0;
    background: none;
    border: none;
    cursor: pointer;
}

.hamburger {
    border: 0;
    cursor: pointer;
    background-color: transparent;
}

.sidebar-label{
    margin-top: 5px;
}

@media only screen and (max-width: 900px) {
    .top-bar ul {
        display: none;
    }

    .top-bar.expanded ul {
        display: block;
        position: absolute;
        top: 6vh;
        left: 0;
        flex-direction: column;
        align-items: baseline;
        width: 100%;
        height: calc(100vh - 6vh);
        background-color: white;
        margin: 0;
        padding: 20px;
        z-index: 10;
    }

    .top-bar ul li {
        padding-top: 1rem;
        margin: 0 0 0 1rem;
    }

    .top-bar ul li a {
        color: rgb(0, 0, 0);
    }

    .top-bar ul .arrow-container img {
        display: none;
    }

    .top-bar .left-container .search {
        display: none;
    }

    .top-bar .mobile-menu {
        display: flex;
    }

    .top-bar {
        height: 6vh;
    }

    .top-bar .left-container .logo {
        width: 8rem;
        transform: translateY(-0.15rem);
    }

    .side-bar {
        display: none;
    }

    .side-bar.expanded {
        display: flex;
        background-color: white;
        position: absolute;
        align-items: baseline;
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: 0;
        padding: 15px 20px;
        top: 15.5rem;
        border-top: rgb(60, 60, 60) solid 0.5px;
        border-bottom: rgb(60, 60, 60) solid 0.5px;
        z-index: 11;
    }

    .navbar__img {
        display: none;
    }

    .sidebar-item p {
        color: black;
        margin: 0 0 0 1rem;
    }

    .sidebar-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 2rem;
        padding: 1.2rem 0;
        margin: 0;
    }

    .top-bar ul .arrow-container {
        position: absolute;
        ;
        top: 22rem;
        background-color: white;
    }

}

.arrow-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    list-style: none;
    top: 45px;
    right: 1px;
    background-color: #213546;
    min-width: 125px;
    z-index: 1;
    list-style: none;
    padding: 10px;
    margin: 0;
}

.dropdown-content a {
    padding: 10px;
    display: flex;
    text-decoration: none;
    color: #fff;
}

html {
    font-size: 1.5vh;
  }
  body {
    font-family: Montserrat;
  }
  .highlight {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* background-color: white; */
    color: black;
    /* display: flex; */
    padding: 20px 0 20px;
    /* justify-content: center; */
    /* align-items: center; */
  }
  .background {
    /* position: relative; */
    /* height: 250px; */
    height: 25vh;
    width: 90%;
    margin: -26.5vh auto 0;
    box-shadow: inset 0 0 1000px rgba(255, 255, 255, 0.753);
    /* background-color: rgba(255, 255, 255, 0.863); */
    border-radius: 10px;
    /* filter: blur(1px); */
    z-index: 0;
    border: 1px solid white;
    transition: 0.5s;
  }
  /* .grid-container:hover ~ .background, .background:hover{
        background-color: rgba(255, 255, 255, 0.445);
        box-shadow: inset 0 0 1000px rgb(255, 255, 255);
        cursor: pointer;
    } */
  .grid-container:hover {
    cursor: pointer;
  }
  
  #errorBanner
  {
    text-align:start;
    display: none;
    background-color: lightcoral;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
  
  }
  
  #errorBannerSave
  {
    text-align:start;
    display: none;
    background-color: lightcoral;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
  
  }
  
  #savedBanner
  {
    text-align:start;
    display: none;
    background-color: #5ea749;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
  
  }
  
  
  .grid-container {
    position: static;
    display: grid;
    max-width: none;
    width: 90%;
    height: 23.5vh;
    grid-template-columns: 1fr 3fr 2fr 2fr;
    margin: 15px auto;
  }
  .grid-container > * {
    z-index: 1;
    overflow-y: auto;
  }
  .grid-container h2 {
    font-size: 2rem;
  }
  .navbar {
    background-color: #203546;
  }
  .company-logo {
    /* justify-self: end; */
    margin: 0 auto 35px;
    align-self: center;
  }
  
  .section1 {
    align-self: center;
  }
  .section1 p {
    margin: 4% 0 0;
    color: #1daf5c;
  }
  .section2 p {
    display: inline;
    margin-left: 10px;
  }
  .section2 h5,
  .section3 h5 {
    margin-bottom: 12.5px;
  }
  .qualification-item {
    display: block;
    margin: 0 0 5px 20px;
  }
  .section3 li {
    z-index: 1;
    margin-bottom: 5px;
  }
  .section3 {
    position: static !important;
    z-index: 0;
    width: 70%;
    overflow: hidden;
  }
  /* below highlight */
  .topmatches {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: black;
    padding: 10px 0 0 0;
  }
  
  .grid {
    display: grid;
    max-width: none;
    width: 90%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 50px 183;
    grid-auto-rows: 183;
    margin: 0 auto;
  }
  .grid .header {
    grid-column-start: 1;
    grid-column-end: 4;
    color: #203546;
    padding-left: 15px;
    align-content: space-between;
  }
  .seemore {
    grid-column: 4/5;
    grid-row: 1/2;
    color: #203546;
    justify-self: right;
    text-decoration: underline;
  }
  .seemore:hover {
    cursor: pointer;
  }
  
  .placard {
    display: grid;
    background-color: white;
    padding: 20px 20px 10px 20px;
    margin: 15px;
    border-radius: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 2fr 1fr 1fr 1fr 2fr;
    /* overflow-y: hidden; */
    height: 22vh;
  }
  .placard button {
    border: 1px solid #035da9;
    border-radius: 10px;
    height: 70%;
    align-self: center;
  }
  .placard p,
  h5,
  h6 {
    margin-bottom: 0;
  }
  .placard p {
    font-size: smaller;
  }
  .placard-logo {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    align-self: center;
  }
  .placard-position {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    align-self: center;
  }
  .placard-percentage {
    grid-column: 4 / 5;
    grid-row: 1 / 2;
    justify-self: end;
    color: #1daf5c;
  }
  .placard-company {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
  }
  .placard-location {
    grid-column: 2 / 4;
    grid-row: 3 / 4;
  }
  .placard-applicants {
    grid-column: 4 / 5;
    grid-row: 3 / 4;
  }
  .placard-date {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
  }
  .placard-expire {
    grid-column: 3 / 5;
    grid-row: 4 / 5;
    justify-self: end;
    font-weight: bold;
  }
  .placard-savebtn {
    grid-column: 1 / 3;
    grid-row: 5 / 6;
    background-color: white;
    color: #035da9;
    margin-right: 5px;
  }
  .placard-applybtn {
    grid-column: 3 / 5;
    grid-row: 5 / 6;
    background-color: #035da9;
    color: white;
    margin-left: 5px;
  }
  .placard-alt-header {
    grid-column: 1/5;
    grid-row: 1/2;
    font-weight: bold;
  }
  .similarmatches {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: black;
    padding: 20px 0 50px;
  }
  .placard-tag {
    border: 1px solid #1daf5c;
    border-image-slice: 1;
    background-color: white;
    color: #1daf5c;
    text-align: center;
    border-radius: 10px;
    margin: 0 10px 10px 0;
    padding: 0 7.5px;
    /* width: fit-content; */
    display: inline-block;
  }
  .placard-alt-tag-container {
    grid-column: 1/5;
    grid-row: 2/5;
    overflow-y: auto;
  }
  .border-gradient-purple {
    border-image-source: linear-gradient(to left, #b78efd, #d671b1);
  }
  
  /* modal */
  .modal-wrapper {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: rgb(0, 0, 0);
    padding: 60px 100px 20px;
  }
  
  .modal-grid {
    display: grid;
    max-width: none;
    width: 100%;
    height: 80%;
    grid-template-columns: 1fr 2fr;
    /* grid-auto-rows: max-content; */
    /* margin: 0 auto; */
  }
  
  .modal-company-logo {
    justify-self: end;
    margin-right: 30px;
  }
  .modal-section1 {
    grid-column: 2/4;
    grid-row: 1/2;
  }
  .modal-section1 p {
    margin: 30px 0 0 0;
    color: rgb(8, 223, 8);
    /* justify-self: end; */
  }
  .modal-section2 {
    grid-column: 2/3;
    grid-row: 2/3;
    margin-top: 50px;
    overflow: hidden;
  }
  .modal-section2 p {
    display: inline;
    margin-left: 10px;
  }
  .modal-section2 h5,
  .modal-section3 h5 {
    margin-bottom: 12.5px;
  }
  .modal-section3 {
    grid-column: 3/4;
    grid-row: 2/3;
    margin-top: 50px;
  }
  .modal-section3 li {
    margin-bottom: 5px;
  }
  .modal-section4 {
    grid-column: 3/4;
    grid-row: 1/2;
    justify-self: end;
    align-self: end;
  }
  .modal-section4 p {
    margin-bottom: 0;
  }
  .description {
    /* padding: 0 200px 0 0; */
    margin-top: 50px;
  }
  .description h4 {
    margin-bottom: 15px;
  }
  .buttons {
    /* padding: 0 200px; */
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
  .buttons button {
    border: 1px solid #035da9;
    border-radius: 10px;
    align-self: center;
    width: 45%;
    height: 50px;
  }
  .exit {
    position: absolute;
    right: 60px;
    top: 50px;
    background-color: white;
    border: 0px;
    font-size: 2rem;
  }
  
  .chance-item {
    display: block;
    margin: 0 0 5px;
  }
  .chance-item p {
    display: inline;
    margin-left: 10px;
  }
  .add-btn {
    margin-top: 1rem;
    padding: 0.5rem;
  }
  
  @media (max-width: 1200px) {
    .grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .grid .header {
      grid-column: 1/3;
    }
    .seemore {
      grid-column: 3/4;
    }
    .apply-step {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 770px) {
    .grid {
      grid-template-columns: 1fr 1fr;
    }
    .grid .header {
      grid-column: 1/2;
    }
    .seemore {
      grid-column: 2/3;
    }
    .grid-container {
      grid-template-columns: 1fr 2fr;
      align-items: center;
    }
    .company-logo {
      /* justify-self: end; */
      margin: 0 auto;
      align-self: center;
    }
  
    .modal-wrapper {
      padding: 60px 0 20px;
    }
  
    .modal-grid {
      display: grid;
      max-width: none;
      width: 100%;
      height: 80%;
      grid-template-columns: 1fr 2fr;
      /* grid-auto-rows: max-content; */
      /* margin: 0 auto; */
    }
    .modal-company-logo {
      margin-right: 30px;
    }
    .modal-section1 {
      grid-column: 2/3;
      grid-row: 1/2;
    }
    .modal-section2 {
      grid-column: 1/2;
      grid-row: 3/4;
      margin-top: 40px;
      justify-self: center;
    }
    .modal-section3 {
      grid-column: 2/3;
      grid-row: 3/4;
      margin-top: 40px;
      justify-self: center;
    }
    .modal-section4 {
      grid-column: 2/3;
      grid-row: 2/3;
      justify-self: left;
      margin-top: 20px;
    }
    .apply-container {
      width: 100%;
      /* background-color:lightskyblue; */
    }
    .apply-progress {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .apply-progress-line {
      width: 4rem;
    }
    .apply-progress-circle {
      width: 2%;
    }
  }
  
  @media (max-width: 500px) {
    .grid {
      grid-template-columns: 3fr 1fr;
    }
    .placard {
      grid-column: 1/3;
    }
    .seemore {
      justify-self: center;
    }
  }
  
  .searchbar-container {
    position: static;
    max-width: none;
    width: 90%;
    margin: 15px auto;
  }
  .searchbar {
    margin-left: auto;
    border-radius: 25px;
    margin-right: 20px;
    width: 40%;
    min-width: 300px;
    height: 44px;
    padding-left: 20px;
  }
  .searchbar:focus {
    outline: none;
  }
  .filter {
    cursor: pointer;
  }
  
  .filter-modal h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 200;
    font-size: 40px;
    line-height: 49px;
  }
  
  .filter-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .filter-grid h4 {
    margin: 20px 0 10px 0;
  }
  .multiple-check label {
    margin-left: 15px;
  }
  .grid1,
  .grid2 {
    display: flex;
    flex-direction: column;
  }
  .multiple-check input {
    transform: scale(1.3);
    margin-left: 25px;
  }
  .manual input {
    border: 1px solid black;
    border-radius: 25px;
    width: 90%;
    height: 40px;
    padding-left: 20px;
  }
  .manual input:focus {
    outline: none;
  }
  
  .page1 {
    color: black;
    margin-right: 10px;
  }
  .loader3 {
    position: absolute;
    z-index: 100;
    top: 0;
    display: flex;
    height: 100vh;
    width: 100%;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .loader3::-webkit-scrollbar {
    display: none;
  }
  .loader2 {
    border: 8px solid #f3f3f300;
    border-radius: 50%;
    border-top: 8px solid #4fc984;
    width: 100px;
    height: 100px; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Application Modal */
  .apply-wrapper {
    color: black;
    display: flex;
    flex-direction: column;
  }
  .apply-header {
    display: flex;
    border-bottom: 1px solid rgb(167, 167, 167);
    padding: 15px 0;
  }
  
  .apply-progress {
    width: 100%;
    background-color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-self: center;
    margin: 50px 0 0 0;
    display: flex;
    justify-items: center;
    align-items: center;
  }
  .apply-progress-circle {
    background-color: white;
    border: 1px solid #213546;
    border-radius: 291px;
    width: 0.9vw;
    height: 0.9vw;
    margin: 0 auto;
  }
  .apply-progress-line {
    background-color: #213546;
    width: 9.5vw;
    height: 1px;
    justify-self: center;
    align-self: flex-start;
    margin: 7px 1px 0;
  }
  .apply-progress > span {
    width: 3vw;
    text-align: center;
    align-self: flex-start;
  }
  .apply-step {
    margin-top: 15px;
    font-size: 0.8rem;
  }
  .apply-progress-active > div {
    background: #8ec7a6;
    border: 2px solid #1daf5c;
  }
  .apply-progress-active > p {
    color: #1daf5c;
  }
  
  .apply-btn-wrapper {
    align-self: flex-end;
    max-width: 40%;
    display: flex;
    justify-content: space-around;
    margin-top: 3rem;
    margin-right: 1rem;
    margin-left: auto;
  }
  .apply-btn-wrapper .backbtn {
    width: 10vw;
    height: 3vw;
    min-width: 113px;
    min-height: 34px;
    background: white;
    border-radius: 8px;
    border-color: #035da9;
    color: #035da9;
    font-family: Montserrat;
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    margin-right: 1.5rem;
  }
  .apply-btn-wrapper .nextbtn {
    width: 10vw;
    height: 3vw;
    min-width: 113px;
    min-height: 34px;
    background: #035da9;
    border-radius: 8px;
    color: white;
    font-family: Montserrat;
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
  }
  .add-btn {
    margin-top: 2rem !important;
    color: rgb(26, 180, 129) !important;
  }
  .css-yk16xz-control,
  #upload {
    background-color: rgb(231, 231, 231) !important;
    cursor: pointer;
    display: block;
    width: 30vw;
  }
  .css-tj5bde-Svg {
    color: black;
    cursor: pointer;
  }
  .select-accordion {
    width: 60%;
    margin-top: 1rem;
  }
  /* .apply-page-long input {
        min-height: 2rem;
      } */
  .MuiSlider-thumb {
    width: 18px !important;
    height: 18px !important;
    margin-top: -7px !important;
  }
  .MuiSlider-root {
    max-width: 40%;
  }
  .MuiSlider-track {
    height: 6px !important;
    border-radius: 16px !important;
  }
  .align-radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .MuiDialog-paperWidthSm {
    position: absolute;
    left: 1rem;
  }
  @media only screen and (max-width: 650px) {
    .apply-progress {
      align-items: center;
    }
    .apply-progress-line {
      width: 3.5rem;
    }
    .apply-progress-circle {
      width: 1rem;
      height: 1rem;
    }
    .apply-step {
      font-size: 0.7rem;
      width: 3.5rem;
      text-align: center;
      margin: 6px 2px;
      margin-right: 2px;
      overflow: hidden;
      margin-left: -8px;
    }
    .apply-btn-wrapper {
      max-width: 70%;
      justify-content: space-around;
      margin-right: 0;
    }
    .exit {
      right: 1rem;
      font-size: 1.8rem;
      top: 0;
    }
    .apply-btn-wrapper .backbtn {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.9rem;
    }
    .apply-btn-wrapper .nextbtn {
      max-width: 1rem;
      height: 1rem;
      font-size: 0.9rem;
    }
    .select-accordion {
      width: 80%;
      margin-top: 1rem;
    }
    #myrange {
      min-height: 0.5rem;
      padding: 1rem;
    }
    .MuiSlider-root {
      max-width: 80%;
    }
    .apply-wrapper {
      height: 100%;
    }
    .apply-page-long {
      min-height: 80%;
    }
    form {
      height: 100%;
    }
  }
  .apply-page-long h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 2vw;
    line-height: 1.8vw;
    margin-top: 1rem;
  }
  .apply-page-long h3 {
    font-size: 1.6vw;
  }
  .apply-page-long h4 {
    font-size: 1.1vw;
  }
  
  .apply-page-long p {
    font-style: normal;
    /* font-weight: 600; */
    font-size: 1vw;
    line-height: 1.5vw;
    margin-top: 1rem;
  }
  .apply-page-long label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.1vw;
    margin-top: 1rem;
    display: block;
  }
  form {
    margin-left: 1rem;
  }
  .apply-page-long input {
    display: block;
    background: #f7f7f7;
    border: 1px solid #c4c4c4;
  
    box-sizing: border-box;
    border-radius: 2px;
    width: 60%;
    height: 3.5vw;
    /* min-height:2rem; */
    padding-left: 1%;
    cursor: pointer;
  }
  .apply-page-long .input-radio {
    display: inline;
    width: 16px;
    height: 16px;
  
    border-radius: 5px;
    margin: 0 10px 0 10px;
  }
  .file-wrapper {
    display: block;
    background: #f7f7f7;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 10px;
    width: 50%;
    min-height: 100px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  .apply-page-long input[type="file"] {
    border: none;
  }
  .input-radio {
    width: 5% ;
  }
  .radio-label {
    margin-bottom: 10px;
    margin-top: 0;
  }
  .apply-page-long select {
    width: 60%;
    height: 3.5vw;
    display: block;
    background: #f7f7f7;
    border-radius: 2px;
    border: 1px solid #c4c4c4;
    padding-left: 10px;
    margin-bottom: 20px;
  }
  
  .apply-page-long textarea {
    display: block;
    background: #f7f7f7;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 10px;
    width: 60%;
    min-height: 100px;
  }
  .apply-page-long .date-wrapper {
    width: 17%;
    margin-right: 2%;
    display: inline-block;
  }
  .apply-page-long .input-date {
    width: 100%;
  }
  .apply-deletebtn {
    width: 12%;
    height: 2.5vw;
  
    background: #ffe4e4;
    border-radius: 8px;
    border: 1px solid rgb(255, 203, 203);
  }
  /* .apply-page-long .input-label{
        display: inline-block;
    } */
  /* .create{
        background:  black;
    }
    .css-2b097c-container{
        width: 50%;
    } */
  .half-width {
    width: 50%;
    font-size: 0.8vw;
  }
  .projects-deletebtn {
    margin-top: 30px;
  }
  .flex-test {
    display: flex;
    justify-content: space-between;
  }
  
  .awards-date-wrapper {
    margin-right: 2%;
    display: inline-block;
    width: 36%;
  }
  .additional-date-wrapper {
    display: block;
    width: 50%;
  }
  
  .slider {
    /* border-radius: 0 !important; */
    padding: 0 !important;
    -webkit-appearance: none;
    width: 100%;
    height: 8px !important;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
    margin: 1rem;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.4rem;
    height: 1.4rem;
    background: #04aa6d;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04aa6d;
    cursor: pointer;
  }
  .review-grid {
    display: grid;
    grid-template-columns: 2fr 4fr 1fr;
    margin-top: 40px;
  }
  .subtitle-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
  @media only screen and (max-width: 557px) {
    .apply-container {
      height: 70vh;
    }
    .apply-page-long h2 {
      font-size: 4.1vw;
    }
    .apply-page-long h3 {
      font-size: 1rem;
    }
    .apply-page-long h4 {
      font-size: 0.8rem;
    }
    .apply-page-long p {
      font-size: 0.7rem;
      line-height: 1rem;
    }
    form input {
      width: 85%;
      height: 2rem !important;
    }
    form textarea {
      width: 85%;
    }
    /* .input-label{
        width:5rem;
    } */
    .date-wrapper {
      width: 30% !important;
    }
    .apply-page-long select {
      margin-bottom: 3px;
      height: 3vh !important;
      border-radius: 3px;
    }
    form label {
      /* height:1rem; */
      line-height: 1.5rem !important;
    }
  
    .css-2b097c-container {
      width: 50%;
    }
    */ .half-width {
      width: 50%;
      font-size: 0.8vw;
    }
    .projects-deletebtn {
      margin-top: 30px;
    }
    .flex-test {
      display: flex;
      justify-content: space-between;
    }
  
    .banner {
      display: none;
      text-align: center;
      margin: 0;
      width: 100%;
      background-color: lightcoral;
      padding: 20px;
  
      margin-bottom: 10px;
    }
  
    .awards-date-wrapper {
      margin-right: 2%;
      display: inline-block;
      width: 36%;
    }
    .additional-date-wrapper {
      display: block;
      width: 50%;
    }
  
    .slider {
      /* border-radius: 0 !important; */
      padding: 0 !important;
      -webkit-appearance: none;
      width: 100%;
      height: 8px !important;
      background: #d3d3d3;
      outline: none;
      opacity: 0.7;
      transition: opacity 0.2s;
      margin: 1rem;
    }
  
    .slider:hover {
      opacity: 1;
    }
  
    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 1.4rem;
      height: 1.4rem;
      background: #04aa6d;
      cursor: pointer;
      border-radius: 50%;
    }
  
    .slider::-moz-range-thumb {
      width: 25px;
      height: 25px;
      background: #04aa6d;
      cursor: pointer;
    }
    .review-grid {
      display: grid;
      grid-template-columns: 2fr 4fr 1fr;
      margin-top: 40px;
    }
    .apply-deletebtn {
      width: 20%;
      height: 5.5vw;
    }
    .css-yk16xz-control {
      width: 80%;
    }
  
    .css-1pahdxg-control {
      min-width: 300px;
    }
  
    .css-2613qy-menu {
      min-width: 200px;
    }
  }
  .post-button{
    border-radius: 5px;
    background-color: #005FAF;
    padding: 10px;
    font-weight: 700;
    color: white;
    border-color: transparent;
    font-family: Montserrat;
    font-size: large;
  }
  .post-button:hover{
    background-color: #024f8e;
  }
form {
  margin: 0;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  /* -webkit-appearance: none; */
  /* Remove most all native input styles */
  -webkit-appearance: none;
          appearance: none;
  /* For iOS < 15 */
  background-color: white;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  border: 0.15em solid currentColor;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:checked {
  background-color: #8EC7A6;
  border-color: #1daf5c;
}

input[type="radio"]:checked  {
  background-color: #8EC7A6;
  border-color: #1daf5c;
}

.apply-page-long .question-title  {
  display: block;
  margin: 1rem 0 0.8rem 0; 
  font-size: 1.3rem;
  line-height: 17px;
}


.internship-details-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "left right"
    "left apply";
  width: 100%;
}

.internship-details-grid-left {
  grid-area: left;

}

.internship-details-grid-right {
  grid-area: right;
}

.internship-review-edit-text {
  color: #035DA9;
}

.internship-review-edit-text:hover {
  cursor: pointer;
  text-decoration: underline;
}

.internship-review-details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  width: 100%;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.internship-review-details-grid-left {

}

.internship-review-details-grid-right {
  display: grid;
  grid-template-columns: 1fr 30px;
}

.internship-review-details-grid-right-remaining {
  display: grid;
  grid-template-columns: 1fr 30px;
}

.internship-review-full-details-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.apply-btn-wrapper {
  grid-area: apply;
}

.apply-page-long select {
  width: 80%;
  margin-top: 0.5rem;
  margin-bottom: 0;
  background: #f7f7f7;}

.apply-page-long input {
  width: 80%;
}

.apply-page-long textarea {
  width: 80%;
}

/* Application Modal */
.apply-wrapper {
  color: black;
  display: flex;
  flex-direction: column;
}

.apply-header {
  display: flex;
  border-bottom: 1px solid rgb(167, 167, 167);
  padding: 15px 0;
}

.apply-progress {
  width: 100%;
  background-color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
  margin: 50px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.apply-progress-circle {
  background-color: white;
  border: 1px solid #213546;
  border-radius: 291px;
  width: 0.9vw;
  height: 0.9vw;
  margin: 0 auto;
}
.apply-progress-line {
  background-color: #213546;
  width: 11vw;
  height: 1px;
  justify-self: center;
  align-self: flex-start;
  margin: 7px 1px 0;
}
.apply-progress > span {
  width: 3vw;
  text-align: center;
  align-self: flex-start;
}
.apply-step {
  margin-top: 15px;
  width: 10rem;
  font-size: 0.8rem;
}
.apply-progress-active > div {
  background: #8ec7a6;
  border: 2px solid #1daf5c;
}
.apply-progress-active > p {
  color: #1daf5c;
}

span {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items:center;
}

.apply-btn-wrapper {
  align-self: flex-end;
  max-width: 40%;
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
  margin-right: 1rem;
  margin-left: auto;  
}
.apply-btn-wrapper .backbtn {
  width: 10vw;
  height: 5vh;
  min-width: 113px;
  min-height: 34px;
  background: white;
  border-radius: 5px;
  border-color: #035da9;
  color: #035da9;
  font-family: Montserrat;
  font-style: normal;
  font-size: 2vh;
  line-height: 29px;
  margin-right: 1.5rem;
}
.apply-btn-wrapper .nextbtn {
  width: 10vw;
  height: 5vh;
  min-width: 113px;
  min-height: 34px;
  background: #035da9;
  border-radius: 5px;
  border: none;
  color: white;
  font-family: Montserrat;
  font-style: normal;
  font-size: 2vh;
  line-height: 29px;
}

.nextbtn:disabled {
  background: #acacac;
}

@media (max-width: 770px) {
  .internship-details-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 6fr 3fr 0px;
    grid-template-areas:
      "left"
      "right"
      "apply";
    width: 100%;
  }
  
  .internship-details-grid-left {
    grid-area: left;
  }
  
  .internship-details-grid-right {
    padding-top: 2rem;
    grid-area: right;
  }

  /* .apply-wrapper {
    height: 60%;
  } */
  
  .apply-btn-wrapper {
    grid-area: apply;
  }

  .apply-page-long select {
    width: 100%;
  }
  
  .apply-page-long textarea {
    width: 100%;
  }

  .apply-page-long .question-title  {
    margin: 0.4rem 0 0.4rem 0; 
    font-size: 1rem
  }

  .apply-page-long select {
    width: 70%;
    margin-top: 0.2rem;
    margin-bottom: 0;
    background: #f7f7f7;
  }

  .apply-page-long input {
    width: 100%;
  }

  .select-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;

  }

  .select-container span {
    width: 60%;
  }

  .internship-details-grid-right .question-title {
    margin: 0.8rem 0 0.8rem 0;
  }

  .apply-container {
    width: 100%;
    /* background-color:lightskyblue; */
  }
  .apply-progress {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .apply-progress-line {
    width: 5rem;
  }
  .apply-progress-circle {
    width: 2%;
  }
}

@media only screen and (max-width: 650px) {
  .apply-progress {
    align-items: center;
  }
  .apply-progress-line {
    width: 4rem;
  }
  .apply-progress-circle {
    width: 1rem;
    height: 1rem;
  }

  .apply-progress > span {
    width: 5.5rem;
  }

  .apply-step {
    font-size: 0.7rem;
    width: 5.5rem;
    text-align: center;
    margin: 6px 2px;
    margin-right: 2px;
    overflow: hidden;
    margin-left: -8px;
  }
  .apply-btn-wrapper {
    max-width: 70%;
    justify-content: space-around;
    margin-right: 0;
  }
  .exit {
    right: 1rem;
    font-size: 1.8rem;
    top: 0;
  }
  .apply-btn-wrapper .backbtn {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.9rem;
  }
  .apply-btn-wrapper .nextbtn {
    max-width: 1rem;
    height: 1rem;
    font-size: 0.9rem;
  }
  .select-accordion {
    width: 80%;
    margin-top: 1rem;
  }
  #myrange {
    min-height: 0.5rem;
    padding: 1rem;
  }
  .MuiSlider-root {
    max-width: 80%;
  }
  .apply-wrapper {
    height: 80%
  }
  .apply-page-long {
    min-height: 80%;
  }
  form {
    height: 100%;
  }
}



/* 
  Page Two Styling
*/

.requirements-details-grid {
  display: grid;
  grid-template-columns: 6fr 4fr;
  grid-template-rows: 1fr 50px;
  grid-template-areas:
    "left right"
    "left right"
    "down apply";
    width: 100%;
}

.requirements-details-grid-left {
  grid-area: left;
}

.requirements-details-grid-down {
  grid-area: down;
}

form .skill-input {
  border-radius: 7px 7px 0 0;
}

.skills-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
  margin-top: -1px;
  background: white;
  border: 1px solid rgb(192, 191, 191);
  border-radius: 0 0 7px 7px;
  width: 80%;
  min-height: 11vh;
}

.skill {
  display: flex;
  align-items: center;
  border: 1px solid rgb(192, 191, 191);
  border-radius: 10px;
  padding: .2rem 0 0 .7rem;
  margin-left: 5px;
  margin-top: .5rem;
  margin-bottom: .5rem;
  max-height: 3vh;
}

.skill p {
  font-size: 1.2rem;
  font-weight: 500;
}

.skill button {
  border: none;
  background: none;
}

.skill button:hover {
  cursor: pointer;
}

.skill button img {
  height: 2.2vh;
}

.legal-link {
  color: black;
}

@media (max-width: 770px) {
  .requirements-details-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 6fr 2.25fr 0px;
    grid-template-areas:
      "left"
      "down"
      "apply";
    width: 100%;
  }

  .requirements-details-grid-down {
    grid-area: down;
  }

  .requirements-details-grid-down .question-title {
    margin: 0.8rem 0 0.8rem 0;
  }
  
  form .skills-container {
    width: 100%;
    min-height: 5vh;
  }

  .skill {
    height: 2.5vh;
  }

  form {
    margin-top: -1rem;
  }

  .apply-btn-wrapper {
    align-self: flex-end;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-right: 1rem;  
  }
}
.profile-div {
  min-width: 100vw;
  width: -webkit-max-content;
  width: max-content;
  min-height: 100vh;
  height: -webkit-max-content;
  height: max-content;
  padding: 3rem 1rem;
  background-color: white;
  z-index: 12;
}

@media (min-width: 768px) {
  .profile-div {
    width: 100vw;
    height: 100vh;
    padding: 3rem 4rem;
    background-color: white;
    height:auto;
  }
}

.profile__exit-button {
  background: none;
  border: none;
}

.profile__overlay-div {
  position:absolute; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  min-height: 100vh; /* Full height (cover the whole page) */
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 12; /* Specify a stack order in case you're using a different order for other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-div__recruiter {
}

/* Recruiter Profile User Styling */

.recruiter-profile-user__main-div {
  display: flex;
  flex-direction: row;
}

.recruiter-profile-user__profile-img {
  height: 8rem;
  width: auto;
  /* height: 7rem;
  width: 7rem; */
}

.recruiter-profile-user__name-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 3rem;
}

.recruiter-profile-user__name {
  font-weight: 600;
  font-size: 30px;
}

.recruiter-profile-user__location {
  font-size: 17px;
  display: block;
}

@media (min-width: 768px) {
  .recruiter-subscription__div {
    align-items: center;
  }

  .recruiter-billing__div {
    align-items: center;
  }
}

/*  */

/* shared */

.profile-user__btn {
  background: none;
  border: none;
  padding: 0;
  margin-bottom: 3px;
}

.profile-user__btn--chosen {
  background: none;
  border: none;
  padding: 0;
  font-weight: 600;
  border-bottom: 3px #035da9 solid;
}

.profile__hr {
  color: #c4c4c4;
  width: 100%;
  margin: 0;
}

.profile--headers {
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 2rem;
}

.profile--link {
  color: #035da9;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

}

.profile--body {
  font-size: 15px;
  height:30vh;
  overflow-y: auto;
}

.profile__contact-info {
  padding: 0.5rem 0;
}

.profile__svg {
  width: 20px;
  height: 20px;
}
/* Subscription Card */

.subscription-card__div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 15rem; */
  padding: 1rem 4rem 2rem 4rem;
  border-radius: 10px;
  border: 1px solid #1daf5c;
}

.sub-card__type {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.sub-card__cost {
  color: #1daf5c;
  font-weight: 600;
  font-size: 35px;
}

.sub-card__per-month {
  font-weight: 600;
  font-weight: 30px;
  margin-top: -8px;
}

/* billing info styling */

.billing-info__info {
  font-weight: 500;
}

.billing-info__row--padding {
  padding: 0.5rem 0;
}

/* Recruiter Profile Body Styling */

.recruiter-profile-body__div {
  background-color: white;
}

/* CompanyProfileUser styling */

.company-profile-user__profile-img-div {
  height: 7rem;
  border-radius: 10px;
  overflow: hidden;
}

.company-profile-user__profile-img {
  height: 7rem;
}

.company-profile-name-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 3rem;
}

.company-profile-name {
  font-weight: 600;
  font-size: 30px;
  padding-top: 1rem;
}

.recruiter-profile-user__location {
  font-size: 17px;
  display: block;
}

@media (min-width: 768px) {
  .recruiter-subscription__div {
    align-items: center;
  }

  .recruiter-billing__div {
    align-items: center;
  }
  .list-profile-internships-container{
    height:40vh;
    overflow-y: auto;
  }
}

/* CompanyProfileBody styling */

.company-profile__company-about-div {
  margin-right: 3rem;
}

/* CompanyInternshipTable styling */

@media (min-width: 768px) {
    .company-internship-table_div {
        padding-right: 7rem;
      }
}


.company-internship-table__first-col {
  width: 75%;
}

.company-internship-table__row {
  padding: 1.5rem 2rem;
  font-weight: 500;
  border-bottom: #035da9 solid 1px;
}

.company-internship-table__row:last-child {
  padding: 1.5rem 2rem;
  font-weight: 500;
  border-bottom: none;
}

.company-internship-table__row--head {
  background-color: #035da9;
  color: white;
  font-weight: 600;
  padding: 1rem 2rem;
}

.dashboard-container {
    grid-area: main;
    margin-left: 2vw;
    margin-right: 2vw;
    padding: 2rem;   
}
@media screen and (max-width: 1030px) {
    .dashboard-container{
        margin-left: 0;

    }
    .dashboard-internship-title-container-main{
        padding-left: 10px !important;
    grid-gap: 15px !important;
    grid-gap: 15px !important;
    gap: 15px !important;
    grid-gap: 10px;
    padding: 10px;
    margin-bottom: 10px;

    }
}
.flex-row-container-header{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 25px;
    .post{
        margin-left: auto;
    } 
    h1{
        margin-bottom: 0;
        text-align: left;
        line-height: 1.5;
        font-weight: 700;
    }   
}

.glass-container {
    background-color: rgba(255, 255, 255, 0.30); 
    -webkit-backdrop-filter: blur(5px); 
            backdrop-filter: blur(5px);
    padding: 40px;
    border-radius: 25px;
    border: none;
}

.dashboard-divider-line {
    border: none; /* Remove default border */
    border-top: 2px solid #1E293B; /* Color and thickness of the line */
    width: 100%; /* Full width of the container */
    margin: 0.5rem 0; /* Space above and below the line */
    opacity: 0.8; /* Slight transparency */
}

.dashboard-zero-internships {
    text-align: center; /* Center align the content */
    padding: 20px; /* Add some spacing */
}
  
.dashboard-zero-internships-text {
    font-size: 16px; /* Adjust text size */
    color: #000; /* Black text */
    line-height: 1.5; /* Spacing between lines */
    margin-bottom: 20px; /* Spacing between text and button */
}

.dashboard-add-job-button2 {
    background-color: #4caf50; /* Green background */
    margin-left: auto;
    color: white; /* White text */
    font-weight: bold; /* Bold text */
    font-size: 16px; /* Text size */
    border: none; /* Remove border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px 20px; /* Spacing inside button */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Button shadow */
    transition: background-color 0.3s ease; /* Smooth hover effect */
    margin-top: 3%;
    margin-bottom: 6%;
}

.dashboard-add-job-button5 {
    background-color: #4caf50; /* Green background */
    margin-left: auto;
    color: white; /* White text */
    font-weight: bold; /* Bold text */
    padding-left: 30px;
    font-size: 16px; /* Text size */
    border: none; /* Remove border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px 20px; /* Spacing inside button */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Button shadow */
    transition: background-color 0.3s ease; /* Smooth hover effect */
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 10px;
    width: 100%;
  }
  
  .pagination-button {
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    margin: 0 10px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease;
  }
  
  .pagination-button:hover {
    background-color: #3a7bc8;
  }
  
  .pagination-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .pagination-info {
    font-size: 14px;
    font-weight: 500;
    color: #666;
    padding: 0 15px;
  }

  .pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 10px;
    width: 100%;
  }
  
  .pagination-button {
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    margin: 0 10px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease;
  }
  
  .pagination-button:hover {
    background-color: #3a7bc8;
  }
  
  .pagination-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .pagination-info {
    font-size: 14px;
    font-weight: 500;
    color: #666;
    padding: 0 15px;
  }
  
  /* Search Styles */
  .search-container {
    margin: 15px 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .search-container-inline {
    margin-left: 20px;
    width: 300px;
  }

  .search-container-inline input.search-input {
    width: 100%; /* Make the input take full width of container */
    max-width: none; /* Remove max-width constraint */
  }
  
  .search-input {
    width: 80%;
    max-width: 350px;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .search-input:focus {
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
    outline: none;
  }
  
  .search-input::placeholder {
    color: #aaa;
  }
  
  .no-results-message {
    width: 100%;
    text-align: center;
    padding: 30px 0;
    color: #666;
    font-style: italic;
  }

.dashboard-add-job-button3 {
    background-color: #4caf50; /* Green background */
    margin-left: auto;
    color: white; /* White text */
    font-weight: bold; /* Bold text */
    font-size: 16px; /* Text size */
    border: none; /* Remove border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px 20px; /* Spacing inside button */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Button shadow */
    transition: background-color 0.3s ease; /* Smooth hover effect */
}
  
.dashboard-add-job-button2:hover {
    background-color: #3e8e41; /* Darker green on hover */
}

.dashboard-add-job-button3:hover {
    background-color: #3e8e41; /* Darker green on hover */
}
  
.dashboard-add-job-button {
    background-color: #4caf50; /* Green background */
    color: white; /* White text */
    font-weight: bold; /* Bold text */
    font-size: 16px; /* Text size */
    border: none; /* Remove border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px 20px; /* Spacing inside button */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Button shadow */
    transition: background-color 0.3s ease; /* Smooth hover effect */
    margin-top: 20px;
}
  
.dashboard-add-job-button:hover {
    background-color: #3e8e41; /* Darker green on hover */
}

.dashboard-internships-info-container {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    height: auto;
    margin-top: 20px;
}

.dashboard-internship-title-container-main {
    height: 30px;
    display: grid;
    grid-template-columns:  1.2fr 1fr 1.3fr 0.5fr 0.6fr;
    grid-gap: 50px;
    gap: 50px;
    grid-template-rows: 1fr;
    padding-left: 60px;    
}

.api-key-container {
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  
  .api-key-label {
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .api-key-box {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .api-key-text {
    flex-grow: 1;
    font-family: monospace;
    font-size: 14px;
    word-break: break-all;
    color: #333;
  }
  
  .copy-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    margin-left: 10px;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .copy-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .clipboard-icon {
    font-size: 18px;
  }
  
  .api-key-instructions {
    font-size: 12px;
    color: #666;
    font-style: italic;
  }
  
  .api-key-error {
    color: #d32f2f;
    margin: 10px 0;
    padding: 10px;
    background-color: rgba(211, 47, 47, 0.1);
    border-radius: 4px;
  }

@media screen and (max-width: 768px) {
    .glass-container{
        padding:20px;
    }
}
 
.dashboard-internship-info-title {
    font-weight: bold;
    display: flex;
    align-items: center;
}

.dashboard-zero-internships-message {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .dashboard-internship-title-container-main {
        grid-template-columns:  1.2fr 1fr 1.3fr;
    }
}
.internship-info-container {
    padding: 15px;
    background-color: white;
    display: grid;
    grid-template-columns: 1.2fr 1fr 1.3fr 0.5fr 0.5fr;
    grid-template-rows: 1fr;
    cursor: pointer;
    padding-left: 60px;
    grid-gap: 50px;
    gap: 50px;
    border-radius: 10px;
    p {
      font-weight: 700;
      text-align: left;
    }
  }
  
  .internship-info-container:hover {
    background-color: #e2e0e0;
  }
  
  @media screen and (max-width: 1030px) {
    #applicant-item {
      display: none;
    }
    .internship-info-container {
      padding-left: 0;
      grid-gap: 10px;
      gap: 10px;
      padding: 10px;
    }
    .flex-row-container {
      margin-right: 0px;
    }
  }
  
  .flex-row-container {
    display: flex;
    justify-content: space-between;
    grid-gap: auto;
    gap: auto;
  }
  
  .internship-info-item {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .internship-status-container {
    position: relative;
  }
  .internship-dropdown-status {
    display: none;
    position: absolute;
    top: 100%;
    background: #fff;
    width: 100%;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .internship-drop-mini-container {
    display: flex;
    justify-content: center;
  }
  
  .internship-status-container:hover .internship-dropdown-status {
    display: block;
    z-index: 99;
  }

@media only screen and (max-width: 600px) {
  .internship-info-container {
    grid-template-columns: 0.2fr 1fr 0.3fr 0.5fr;
  }
}
  
.jobModal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .jobModal-content {
    margin-top: 4%;
    margin-bottom: 4%;
  }

  .clipboard-icon {
    color: #56C385; /* Green color */
  }
  
  .jobModal-container {
    background-color: #cde1d6; /* Light green background */
    border-radius: 15px;
    padding: 2rem;
    width: 40%;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .jobModal-close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 3.5rem;
    cursor: pointer;
    color: #333;
  }
  
  .jobModal-logo {
    width: 50px;
    height: 50px;
    margin-bottom: 1rem;
  }
  
  .jobModal-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #233242;
    margin-bottom: 0.5rem;
  }
  
  .jobModal-subtitle {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1.5rem;
  }
  
  .jobModal-link-container {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
  }
  
  .jobModal-link {
    flex: 1 1;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.5rem;
    font-size: 1rem;
    color: #333;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .jobModal-copy-button {
    background-color: #7ac903; /* Green background */
    border: none;
    color: white;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.5rem;
    transition: background-color 0.3s ease;
  }
  
  .jobModal-copy-button:hover {
    background-color: #659f02;
  }

  @media only screen and (max-width: 600px) {
    .jobModal-container {
      width: 90%;
    }
  }
.edit-position-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .edit-position-container {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    width: 80%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
  }
  
  .edit-position-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
  }
  
  .edit-position-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .edit-position-form-group {
    margin-bottom: 1.5rem;
  }
  
  .edit-position-form-inline {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .edit-position-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  .edit-position-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .edit-position-button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: 500;
  }
.applicants-root {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 15px;
}

.applicants-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin-right: 25px;
    width: 100%;
}

.applicants-header-text {
    font-weight: bold;
    font-size: 24px;
}

.applicants-header-button {
    font-weight: bold;
    cursor: pointer;
}

.applicant-modal-background {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: 15% auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 0.09vw solid #888;
    width: 70%;
    z-index: 20;
    align-items: center;
    min-height: 300px;
}

.close-modal-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.applicant-modal-close-button {
    cursor: pointer;
    font-size: 25px;
}


.applicant-modal-body {
    height: 20vw;
    font-size: 1vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    background-color: #f1f1f1;
    border-left: 0.09vw solid black;
    border-right: 0.09vw solid black;
}


.applicant-modal-body-column-one {
    margin-top: 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.7vw;
    gap: 0.7vw;
}

.applicant-modal-body-column-two {
    margin-top: 3vw;
    display: flex;
    flex-direction: column;
    align-items: start;
    grid-gap: 0.7vw;
    gap: 0.7vw;
}

.applicant-modal-info-image {
    height: 8.96vw;
    width: 8.96vw;
    max-width: 8.96vw;
}

.applicants-title-container {
    font-size: 1.2vw;
    height: 2.1vw;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    margin-bottom: 1vw;
}

.applicants-internship-info-title {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.applicant-internship-info-container {
    height: 2.5vw;
    width: 100%;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    cursor: pointer;
}

.applicant-internship-info{
    font-size: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu{
    font-size: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-status{
    font-size: 1vw;
    width: 8vw;
    height: 1.5vw;
}
.edit-status{
    font-size: 1vw;
    width: 8vw;
    height: 1.5vw;
}
.name{
    font-weight: bold;
    font-size: 1.75vw;
    margin-bottom: 1.4vw;
}
.contact-info{
    font-weight: bold;
    font-size: 1.4vw;
}
.contact-app{
    font-weight: light;
    font-size: 0.7vw;
    font-style:italic ;
}

/* Add the following styles to your existing CSS file */

.accordion{
    width: 100%;
}
  .accordion-item-header {
    margin-top: -0.6vw;
    font-size: 1vw;
    height: 3vw;
    cursor: pointer;
    background-color: #f1f1f1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    border-top: 0.09vw solid black;
    border-left: 0.09vw solid black;
    border-right: 0.09vw solid black;
  }
  
  .accordion-item-body-content {
    line-height: 1vw;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .accordion-item-body-content.active {
    max-height: 100vw; 
  }
  .email-image, .telephone-image, .location-image, .download-image{
    width: 1.68vw;
    height: 1.68vw;
  }
  .eyeopen-image{
    width: 2.24vw;
    height: 2.24vw;
  }
  
  @media (max-width: 1140px) {
    .accordion-item-header {
        margin-top: -1.1vw;
    }
    .edit-status{
        width: 8vw;
        height: 1.5vw;
    }
    .edit-status2{
        height:1.5vw;
    }
  }
  
  @media (max-width: 430px) {
    .applicants-header-text{
        font-size: 3vw;
    }
    .accordion-item-header {
        height: 5vw;
        margin-top: -2.2vw;
    }
    .applicant-internship-info{
        font-size: 1.7vw;
    }
    .edit-status{
        margin-top: -2.2vw;
        width: 13vw;
        height: 0.6vh;
        font-size: 0.7vw;
    }
    .edit-status2{
        width:15vw;
        font-size:1vw;
    }

    .menu{
        font-size: 1vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
 
  
.dashboard-container {
  padding: 20px;
  background-color: #cde1d6;
  min-height: 100vh;
}

.glass-container {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.flex-row-container-header2{
  display: flex;
  align-items: center; 
  justify-content: space-between; 
  margin-bottom: 20px;
  .post{
      margin-left: auto;
  } 
  h1{
      margin-bottom: 0;
      text-align: left;
      line-height: 1.5;
      font-weight: 700;
  }   
 }

 .button-container {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
 }

 .dashboard-internship-title-container2 {
  height: 20px;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr 1fr 1fr; /* Adjusted column sizes */
  grid-gap: 30px;
  gap: 30px;
  grid-template-rows: 1fr;
}

.dashboard-internship-info-title:last-child {
  margin-left: 30%; /* Add margin to move "Interview Results" to the right */
  text-align: left; /* Optional to align the text within the column */
}

.back-button-applicantList {
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.sub-title {
  font-size: 14px;
  color: #1DAF5C;
  font-weight: bold;
  margin-bottom: 30px;
}

.dashboard-internship-title-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr 2fr;
  text-align: left;
  margin-bottom: 10px;
  font-weight: bold;
}

.dashboard-divider-line {
  margin: 10px 0;
}

.dashboard-internships-info-container {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.dashboard-internship-row {
  display: grid;
  grid-template-columns: 0.8fr 1.0fr 1.8fr 1.5fr 1.2fr;
  align-items: center;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-internship-info {
  text-align: left;
  font-size: 14px;
}

.status-applied {
  color: #555;
  font-weight: bold;
}

.view-results-button {
  background-color: #233242;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.inline-modal {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  position: relative;
  width: 100%; /* Adjust width as needed */
  z-index: 1; /* Ensure it appears on top */
}

.modal-grid {
  display: grid;
  grid-template-columns: 1fr 2fr; /* Left column 1fr, Right column 2fr */
  grid-gap: 20px;
  gap: 20px; /* Space between columns */
}

.left-column {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px; /* Spacing between items */
}

.right-column {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px; /* Spacing between items */
  overflow-y: auto; /* Scroll if content overflows */
  max-height: 80vh; /* Prevent overflowing modal */
}

.modal-content2 {
  background-color: #fefefe;
  flex-direction: column;
  padding: 10px;
  /* width: 30%; */
  z-index: 20;
  align-items: center;
}

.date-text {
  font-size: 12px;
  margin-bottom: 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  color: #333;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.location-status {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.location-icon {
  width: 16px; /* Adjust the width as needed */
  height: 16px; /* Adjust the height as needed */
  margin-right: 8px; /* Spacing between the icon and the status text */
}

.hire-reject-buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px; /* Adjust spacing between buttons */
  margin-top: 10px; /* Add some spacing from the element above */
}

.hire-button {
  background-color: #4caf50; /* Green background */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.hire-button:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}

.reject-button {
  background-color: #f44336; /* Red background */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}

.reject-button:hover {
  background-color: #e53935; /* Slightly darker red on hover */
}

.loaderForInterviewSent {
  border: 4px solid #f3f3f3; /* Light gray */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.custom-email-sent {
  display: flex !important;
  align-items: flex-start !important;
}

input[type="checkbox"] {
  cursor: pointer;
}

.qa-question-text-qbqAnalysis {
  font-size: 14px; /* Same as accordion-body text */
  margin: 0; /* Remove any default margin */
  font-weight: 400; /* Bold for emphasis */
}

.accordion-button-qbqAnalysis {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  cursor: pointer;
  background-color: transparent; /* Transparent background */
  border: none; /* Remove button border */
  padding: 10px 15px; /* Consistent padding */
  font-size: 16px; /* Match font size */
  transition: background-color 0.2s ease; /* Smooth hover */
}

.accordion-button-qbqAnalysis:hover {
  background-color: #f9f9f9; /* Light hover background */
}

.qa-toggle-icon-qbqAnalysis {
  margin-left: 10px; /* Space between question and icon */
  width: 16px; /* Icon size */
  height: 16px; /* Icon size */
}

.accordion-item-qbqAnalysis {
  margin-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
}

.accordion-body-qbqAnalysis {
  font-size: 14px;
  line-height: 1.5;
}

.accordion-container-qbqAnalysis {
  margin: 5px 0;
  margin-left: -10px;
}

.accordion-header-qbqAnalysis {
  margin-bottom: 10px;
}

.accordion-collapse-qbqAnalysis {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
}

.dashboard-add-job-button4 {
  background-color: #4caf50; /* Green background */
  margin-left: auto;
  color: white; /* White text */
  font-weight: bold; /* Bold text */
  font-size: 16px; /* Text size */
  border: none; /* Remove border */
  border-radius: 8px; /* Rounded corners */
  padding: 10px 20px; /* Spacing inside button */
  cursor: pointer; /* Pointer cursor on hover */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Button shadow */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.dashboard-add-job-button4:hover {
  background-color: #3e8e41; 
} 

.download-candidates-button {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
  background-color: #e0e0e0; /* Light gray background */
  border: none;
  border-radius: 8px; /* Rounded corners */
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 500;
  color: #000; /* Black text */
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: all 0.3s ease; /* Smooth hover effect */
  margin-right: 10px;
}

.download-candidates-button:hover {
  background-color: #d6d6d6; /* Darker gray on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); /* Slightly larger shadow */
}

.download-candidates-button:active {
  transform: translateY(2px); /* Button press effect */
}

.download-candidates-button img {
  width: 18px;
  height: 18px;
}

.kanban-dashboard-internship-title-container2 {
  display: flex;
  grid-gap: 42px;
  gap: 42px;
  padding: 10px 0;
  border-bottom: 2px solid #ccc;
}

.kanban-dashboard-tab {
  background: #f9f9f9;
  border: none;
  padding: 5px 35px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.2s ease-in-out, color 0.2s;
}

.kanban-dashboard-tab:hover {
  background: #dcdcdc;
}

.kanban-dashboard-tab.active {
  background: #1f2937; /* Dark navy shade for active tab */
  color: white;
  font-weight: bold;
}

.kanban-applicant-row {
  width: 100%;
  background-color: white;
  border: 8px solid #e0e0e0;
  border-radius: 3px;
  padding: 8px 8px 8px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
  display: flex;
  flex-direction: column;
}

.kanban-applicant-row:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-4px);
}

.kanban-applicant-row.selected {
  border: 2px solid #4CAF50; 
  background-color: #E8F5E9; 
}

.kanban-applicant-container {
  width: 35%; 
  background-color: #f0f0f0;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;
}

.kanban-applicant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
}

.kanban-applicant-name {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.kanban-applicant-location {
  color: #333;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 14px;
  margin-left: 40px;
}

.kanban-applicant-links {
  color: #333;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  margin-bottom: 12px;
}

.kanban-applicant-link {
  text-decoration: underline;
  font-size: 14px;
  color: #333;
}

.kanban-applicant-date {
  font-size: 12px;
  color: #888;
}

.kanban-applicant-selector {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.kanban-applicant-selector input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.kanban-applicant-header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  background-color: #f0f0f0;
  border-radius: 8px;
  margin-bottom: 16px;
}

.kanban-select-all {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.kanban-select-all input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.kanban-date-sort {
  font-size: 14px;
  color: #666;
}

.candidate-action-container {
  display: flex;
  height: 62px;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 16px;
  margin-left: 10px;
}

.candidate-status-label {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.candidate-action-buttons {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}

.push-to-resume-screening {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}

.push-to-resume-screening:hover {
  background-color: #e0e0e0;
}

.reject-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}

.reject-button:hover {
  background-color: #d32f2f;
}

.candidate-details-container {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  margin-left: 10px;
}

.candidate-name {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
}

.candidate-contact-info {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.contact-detail-row {
  display: flex;
  align-items: center;
}

.contact-label {
  flex-basis: 100px;
  font-weight: 500;
  color: #666;
}

.contact-value {
  color: #333;
}

.kanban-resume-container {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa; /* Light gray background for contrast */
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.kanban-resume-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.kanban-resume-container iframe {
  width: 100%;
  height: 600px;
  border-radius: 5px;
  border: none;
}

.kanban-score-badge {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 6px;
  margin-left: 8px;
  min-width: 40px;
  text-align: center;
}

/* If the candidate has an overall score */
.kanban-score-badge.scored {
  background-color: #4caf50; /* Green */
  color: white;
}

/* If the candidate's score is missing, show "Awaiting" */
.kanban-score-badge.awaiting {
  background-color: #ccc; /* Gray */
  color: black;
}

.resume-score-badge {
  display: inline-block;
  border: 1px solid #333;      
  border-radius: 8px;         
  padding: 4px 8px;            
  font-weight: 600;         
  font-size: 14px;            
  color: #333;                
  background-color: #fff;      
  margin-left: 8px;       
  position: relative;
  top: -10px;     
}

.contact-pill {
  display: inline-flex;
  align-items: center;
  background-color: #e4f3e8; /* or whatever color you prefer */
  padding: 0.5rem 1rem;
  border-radius: 10px; /* super-rounded for a "pill" look */
}

.contact-icon {
  width: 16px;
  height: 16px;
  margin-right: 0.5rem; /* spacing between icon and text */
}

@media only screen and (max-width: 600px) {
  .dashboard-internship-title-container2 {
    height: 35px;
    grid-template-columns: 2.5fr 1fr 3.6fr; 
  }

  .dashboard-internship-row {
    grid-template-columns: 2.8fr 8.0fr 1.8fr;
  }

  .date-text {
    margin-bottom: 0px;
  }

  .modal-grid {
    grid-template-columns: 1fr; /* Stack the columns for mobile */
    grid-gap: 10px;
    gap: 10px; /* Adjust spacing for mobile */
  }

  .right-column {
    margin-top: 20px; /* Add space between left and right content */
  }
}
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-content {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.close-popup-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
}

.popup-content h2 {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
}

.send-email-button {
    background-color: #233242;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    display: block;
    width: 100%;
}

.send-email-button:hover {
    background-color: #1b2838;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: auto;
  }
  
  .modal-content {
    max-height: 80%; 
    overflow-y: auto;
    background-color: #CDE1D6;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .file-upload-area {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    width: 90%;
  }
  
  .file-upload-area input {
    /* display: none; */
  }
  
  .file-upload-area p {
    color: #666;
    margin: 0;
  }
  
  .upload-submit-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .upload-submit-button:hover {
    background-color: #0056b3;
  }

  .parsedResult-inputGroup {
    margin-bottom: 15px;
    font-size: 12px;
  }
  
  .parsedResult-label {
    display: block;
    margin-bottom: 5px;
  }
  
  .parsedResult-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 12px;
    box-sizing: border-box;
  }
  
  .parsedResult-previewButton {
    margin-top: 10px;
    padding: 8px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #555;
  }
  
  .modal-content.blurred {
    filter: blur(5px); 
    pointer-events: none; 
  }
/****** LOGIN ******/

.login {
    display: flex;
    flex-direction: column;
    background-size: cover;
    padding: 2rem 9vw 4rem 2vw;
    height:100vh;
    width:100vw;
    overflow-y: hidden;
    margin-left: 3%;
}

.nav-top img {
    width: 12vw;
    position: absolute;
    top:20px;
    left:20px;
    cursor: pointer;
}

.login-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 7vh;
    width:100%;
    height:100%;
}

.login-container img {
    width: 35vw;
    object-fit: contain;
    height:85%;
}

.login-container .left-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45vw;
    justify-content: center;
    height: 75vh;

    background-color: #FFFFFF; /* White background for the box */
    border-radius: 10px; /* Rounded corners */
    padding: 2rem; /* Inner spacing */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border: 1px solid #E0E0E0; /* Optional: Light border for a clean edge */
    margin: auto; /* Center horizontally */
}

.login-container .left-container h3 {
    color: #213546;
    font-size: 3.75rem;
    font-weight: 600;
    text-align: center;
}

.login-container .left-container .input-container {
    width: 30vw;
    margin-top: 40px;
    margin-bottom: 20px;
}

.login-container .left-container .input-container .text-container {
    display: flex;
    /* flex-direction: row;
    align-items: center; */
    /* margin-bottom: 10px; */
}

.login-container .left-container .input-container .text-container p {
    font-size: 1rem;
    font-weight: 300;
    white-space: nowrap;
    transform: translateY(0.5rem);
    margin: 7px 10px 0 0;
}

.login-container .left-container .input-container .text-container select {
    height: 2.5rem;
    margin-left: 1rem;
    width: 100%;
    padding-left: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: black 1.5px solid;
    outline: none;
    background: transparent;
    font-family: 'Montserrat';
}

.login-container .left-container .input-container input {
    height: 2.5rem;
    font-size: 14px;
    width: 100%;
    padding-left: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: black 1.5px solid;
    outline: none;
    background: transparent;
    margin-top: 0.5rem;
    font-family: 'Montserrat';
    margin-bottom: 10px;
}

.login-container .left-container .input-container input::placeholder {
    color: black;
}

.login-container .left-container p b {
    font-weight: 500;
}

.login-container .left-container .icon-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.login-container .left-container .icon-container .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
}

.btn-text{
    display: flex;           
}
.btn-text > p{
    margin-right: 10px;
}

.login-container .left-container .icon-container .icon img {
    width: 2.5rem;
    object-fit: contain;
}

.login-container .left-container .icon-container p {
    font-size: 0.8rem;
}

.login-container .left-container .icon-container p b {
    font-weight: 500;
}

.login-container .left-container .button {
    display: flex;
    background-color: #213546;
    border-radius: 5px;
    width: 9rem;
    height: 3rem;
    margin: 1rem 0;
    cursor: pointer;
}

.login-container .left-container .button p {
    margin: auto;
    color: white;
    font-size: 1rem;
    font-weight: 600;
}

.login-container .left-container p {
    color: #213546;
    font-weight: 300;
    font-size: 1rem;
    margin-top: 0.25rem;
}

@media only screen and (max-width: 900px) {
    .nav-top img {
        margin-left: 0;
        width: 25vw;
    }
    .login-container {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        height:90%;
    }
    .login-container .left-container {
        width: 84vw;
    }
    .login-container .left-container .input-container {
        width: 40vw;
    }
    .login-container .icon img {
        width: 20vw;
    }
    .login-container .login-img{
        min-width:20vw;
        margin-bottom:3vh;
        height:auto;
        width:30vw;
    }
    .login-container .left-container h3{
        font-size:3.3rem;
    }
    .login{
        padding: 2rem 9vw 2vw;
    }
    

}

@media only screen and (max-width: 710px) {
    .login-container {
        padding-bottom: 2rem;
    }
    .login-container .left-container h3 {
        font-size: 1.75rem;
    }
    .login-container .left-container .input-container {
        width: 55vw;
    }
    .login-container .left-container .input-container input {
        height: 2.25rem;
    }
    .login-container .left-container .input-container .text-container select {
        height: 2.25rem;
    }
    .login-container .left-container .button {
        height: 2.5rem;
    }
    .login-container .left-container{
        height: auto;
    }
}

@media only screen and (min-width: 1300px) {
    .login-container {
        min-height: 92vh;
    }
}


/****** SIGNUP ******/

.signup {
    display: flex;
    flex-direction: column;
    background-size: cover;
    padding: 2rem 9vw 4rem 2vw;
    height:100vh;
    width:100vw;
    overflow-y: hidden;
    margin-left: 3%;
}

.signup-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top:7vh;
    height:100%;
    width:100%;
}

.signup-container .left-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
    justify-content: center;
    height: 80vh;

    background-color: #FFFFFF; /* White background for the box */
    border-radius: 10px; /* Rounded corners */
    padding: 2rem; /* Inner spacing */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border: 1px solid #E0E0E0; /* Optional: Light border for a clean edge */
    margin: auto; /* Center horizontally */
}

.signup-container .left-container h3 {
    color: #213546;
    font-size: 3.75rem;
    font-weight: 600;
    text-align: center;
}

.signup-container .left-container .input-container {
    width: 30vw;
    margin-top: 20px;
}

.signup-container .left-container .input-container .text-container {
    display: flex;
    /* flex-direction: row; */
    /* align-items: center; */
}

.signup-container .left-container .input-container .text-container p {
    font-size: 1rem;
    font-weight: 300;
    white-space: nowrap;
    transform: translateY(0.5rem);
    margin-right:10px;
    margin-top:7px
}

.signup-container .left-container .input-container .text-container .recruiter-box {
    display: flex;
    height: 2.5rem;
    margin-left: 1rem;
    width: 100%;
    padding-left: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: black 1.5px solid;
    outline: none;
    background: transparent;
}

.signup-container .left-container .input-container .text-container .recruiter-box p {
    margin: auto 0;
    font-size: 0.85rem;
    font-weight: 400;
    transform: translateY(0);
}

.signup-container .left-container .input-container .text-container select {
    height: 2.5rem;
    margin-left: 1rem;
    width: 100%;
    padding-left: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: black 1.5px solid;
    outline: none;
    background: transparent;
    font-family: 'Montserrat';
}

.signup-container .left-container .input-container input {
    height: 2.5rem;
    font-size: 14px;
    width: 100%;
    padding-left: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: black 1.5px solid;
    outline: none;
    background: transparent;
    margin-top: 0.5rem;
    font-family: 'Montserrat';
    margin-bottom:10px;
}

.signup-container .left-container .input-container input::placeholder {
    color: black;
}

.signup-container .left-container p b {
    font-weight: 500;
}

.signup-container .left-container .icon-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.signup-container .left-container .icon-container .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
}

.signup-container .left-container .icon-container .icon img {
    width: 2.5rem;
    object-fit: contain;
}

.signup-container .left-container .icon-container p {
    font-size: 0.8rem;
}

.signup-container .left-container .icon-container p b {
    font-weight: 500;
}

.signup-container .left-container .button {
    display: flex;
    background-color: #213546;
    border-radius: 5px;
    width: 9rem;
    height: 3rem;
    margin: 1rem 0;
    cursor: pointer;
}

.signup-container .left-container .button p {
    margin: auto;
    color: white;
    font-size: 1rem;
    font-weight: 600;
}

.signup-container .left-container p {
    color: #213546;
    font-weight: 300;
    font-size: 1rem;
    margin-top: 0.25rem;
}

.text-input-signup {
    position: relative;
    display: flex;
}  

.text-input-signup .eyeicon {
    position: absolute;
    right: 5%;
    bottom: 1.2rem;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
.signup-container img {
    width: 35vw;
    object-fit: contain;
    height:85%;
}

.password-restraint-div {
    width: 100%;
    margin: 15px auto 15px auto;
    display: flex;
    flex-direction: row;
    font-size: small;
}

.password-restraint-1 {
    margin-right: 10px;
}

.password-restraint-1 div {
    display: flex;
    flex-direction: row;
}

.password-restraint-2 {
    margin-right: 10px;
}

.password-restraint-2 div {
    display: flex;
    flex-direction: row;
}

.password-restraint-3 div {
    display: flex;
    flex-direction: row;
}

.password-restraint-text {
    margin-left: 5px;
}

.disabled-signup-btn {
    display: flex;
    background-color: lightslategray;
    border-radius: 5px;
    padding: 12px 35px;
    margin: 1rem 0;
}

#disabled-signup-text {
    margin: auto;
    color: white;
    font-size: 1rem;
    font-weight: 600;
}

.login__forgot-link {
    text-decoration: underline;
    font-weight: 200;
    background: none;
    border: none;
    justify-self: flex-start;
    color: black;
}

@media (max-width: 1580px) {
    .password-restraint-div {
        flex-direction: column;
    }
}

@media only screen and (max-width: 900px) {
    .signup-container {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        height: 90%;
    }
    .signup-container .left-container {
        width: 84vw;
    }
    .signup-container .left-container .input-container {
        width: 40vw;
    }
    .signup-container .icon img {
        width: 20vw;
    }
    .signup-container .signup-img{
        min-width: 20vw;
        margin-bottom: 3vh;
        height:auto;
    }
    .signup-container .left-container h3{
        font-size: 3.3rem;
    }
    .signup{
        padding: 2rem 9vw 2vw;
    }
    .signup-container img {
        width: 30vw;
    }
    
}

@media only screen and (max-width: 710px) {
    .signup-container {
        padding-bottom: 2rem;
    }
    .signup-container .left-container h3 {
        font-size: 1.75rem;
    }
    .signup-container .left-container .input-container {
        width: 55vw;
    }
    .signup-container .left-container .input-container input {
        height: 2.25rem;
    }
    .signup-container .left-container .input-container .text-container .recruiter-box {
        height: 2.25rem;
    }
    .signup-container .left-container .button {
        height: 2.5rem;
    }
    .signup-container .left-container{
        height:auto;
    }
}

@media only screen and (min-width: 1300px) {
    .signup-container {
        min-height: 92vh;
    }
}
@media only screen and (max-width:600px){
    .signup-container .signup-img{
        min-width: 200px;
    }
    .login-container .login-img{
        min-width: 200px;
    }
    .signup {
        margin-left: -3%;
    }
    .signup-container {
        margin-top: 4vh;
    }
    .login {
        margin-left: -3%;
    }
}

.external_icon_container{
  display: flex;
  justify-content: space-evenly;
  min-width:10vw;
}

.abcRioButtonContentWrapper {
  display: flex ;
  flex-direction: column;
  width: 4.6rem;
  max-height: 4.1rem !important; 
  overflow: hidden !important;
  border-radius: 200px;
  background: transparent !important;
}
.abcRioButtonIcon {
  width: 100%;
  margin-top: 7%;
  width: auto;
  padding:0 !important;
  background:white;
  z-index: 20;
  display: flex;
  justify-content: center;
  place-items: center;
  margin-left: -92%;
}

#google-sign-in > div{
  height:100% !important;
  width:100% !important;
  border-radius: 5px;
}
#google-sign-in.google_btn{
  height: 35px;
  width: 35px;

}
div.abcRioButtonContentWrapper{
  height: 100%;
  width: 100%;
  position: relative;
  
}
div.abcRioButtonIcon{
  display: flex;
  position: relative;
  top: 8%;
  left: 59%;
}
div.abcRioButtonContentWrapper .abcRioButtonSvgImageWithFallback{
  height:35px !important;
  width:35px !important;
  position: relative;

}

.abcRioButtonIconImage{
  margin:0 !important;
  
}

div.abcRioButtonIcon {
  width: 100% !important;
  display: flex;
  height: 100% !important;
  align-self: center !important;
  justify-self: center !important;
  z-index: 10;
  padding: 0 !important;
}
svg.abcRioButtonSvg {
  position: relative;
  width:25px !important;
  height:25px !important;


}
.abcRioButtonIcon svg {
  margin: 0;
}

@media only screen and (max-width:900px) {
  .external_icon_container{
    display: flex;
    justify-content: space-evenly;
    min-width: 20vw;
  }
 
}

.forgot-pass__warning {
    color: #F00;
    font-size: 11px;
    margin-top: 3rem;
    margin-bottom: -2rem;
}

.forgot-pass__reset-btn-div {
    margin-top: 2rem;
}

.pass-modal-div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(233, 233, 233, 0.75);
    z-index: 100;
}

.login-modal__inner-div {
    width: 100%;
    height: 100%;
}

.forgot-pass_container {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 2rem 4rem 6rem 4rem;
    border: solid black 1px;
    border-radius: 10px;
    background-color: #B8D9C5;
}

.forgot-pass__exit-btn {
    width: -webkit-min-content;
    width: min-content;
    align-self: flex-end;
    background: none;
    border: none;
    margin-right: -2rem;
}

.forgot-pass__header {
    color: #213546;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.6px;
}

.forgot-pass__body {
    width: 80%;
}


.forgot-pass__bold {
    font-weight: 600;
}

.forgot-pass__text-input {
    border: #213546 solid 1px;
    background-color: rgba(255, 255, 255, 0.52);
    width: 100%;
}

.forgot-pass_reset-btn {
    border: none;
    padding: .8rem 3rem;
    border-radius: 5px;
    color: white;
    background: #213546;
}

.forgot-pass__success-p {
    font-size: 18px;
}

@media only screen and (max-width: 1000px){
    .forgot-pass_container {
        width: 70%;
    }
}

@media only screen and (max-width: 700px) {

    .forgot-pass__header {
        font-size: 27px;
    }

    .forgot-pass__text-input {
        margin-top: 5px;
    }

    form {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 530px) {

    .forgot-pass__header {
        font-size: 20px;
    }

}
* {
    box-sizing: border-box;
}

body {
    background-color: #CDE1D6;
    background-size: cover;
    margin: 0;
    font-size: 14px;
}


.about-box {
    width: 95%;
    position: relative;
    border: 1px solid white;
    border-radius: 22px;
    margin: 2.5rem auto;
    padding: 75px 0;
    /* min-width: 95vh; */
    min-height: 90vh;
    background: rgba(250, 250, 250, 0.336);
}

.about-flex-header {
    display: flex;
    justify-content: center;
    /* border: 1px solid blue; */
    text-align: center;
    font-weight: 500;
    font-size: .8em;
    /* width: 90%; */
}

.about-header-item {
    /* border: 1px solid red; */
    align-self: center;
    /* width: 100%; */
    min-width: 250px;
}

.about-you {
    color: #06ad0e;
}

.about-circle1 {
    position: relative;
    left: 50%;
    margin-bottom: 10px;
    border-radius: 50%;
    height: 13px;
    width: 13px;
    /* margin-left: 50px; */
    background: #7ac903;
    border: 2px solid #06ad0e;
    
}

.about-circle2 {
    position: relative;
    left: 50%;
    margin-bottom: 10px;
    border-radius: 50%;
    height: 13px;
    width: 13px;
    /* margin-left: 50px; */
    border: 2px solid black;
}

.about-line {
    position: relative;
    margin-top: -29px;
    margin-left: -108px;
    margin-right: -120px;
    border: 1px solid black;
}

.about-h1 {
    margin-bottom: -15px;
}

.about-section {
    margin: 3em auto;
    width: 90%;
    /* border: 1px solid blue; */
    /* height: 100vh; */
    padding: 0 3em 0 0;
    /* overflow: hidden; */
}


.about-form {
    display: flex;
    flex-direction: column;
    /* border: 1px solid orange; */
}

.about-form-group {
    padding-top: 1.5em;
}

.about-form-group label {
    font-weight: 500;
}


.about-label, .about-input1 {
    display: block;
}

.about-input1, .about-select1 {
    border-radius: 7px;
    border: 1px solid rgb(192, 191, 191);
}

.about-input1 {
    width: 45%;
    height: 2em;
}

.about-select1 {
    width: 45.2%;
    height: 2em;
}

.about-input1:focus, .about-select1:focus {
    outline: none;
}

.about-input2, .about-select2 {
    display: none;
}

.about-form-button {
    display: flex;
    position: absolute;
    bottom: 3%;
    right: 4rem;
    width: 12%;
    /* border: 1px solid black; */
    text-align: center;
}

.about-button {
    background-color: rgba(7, 53, 179, 0.795);
    color: white;
    padding: 1rem 3rem;
    border-radius: 6px;
    border: none;
    width: 100%;
    min-width: 120px;
    height: 60px;
}



@media only screen and (max-width: 525px) {

    .about-container {
        overflow: hidden;
    }

    .about-box {
        margin: 1em auto 0 auto;
        min-height: 95vh;
        padding: 50px 0;
    }

    .about-flex-header {
        font-size: .5em;
    }

    .about-header-item {
        min-width: 150px;
        position: absolute;
    }

    .about-header-item .about-you {
        margin-right: 160px;
    }

    .about-header-item .about-work-authorization {
        margin-left: 200px;
    }

    .about-circle1 {
        position: relative;
        height: 8px;
        width: 8px;
        margin-left: -84px;
    }

    .about-circle2 {
        position: relative;
        height: 8px;
        width: 8px;
        margin-left: 88px;
    }

    .about-line {
        margin-top: -20px;
        margin-left: -108px;
    }


    .about-section {
        width: 109.5%;
        padding-left: 20px;
        margin: 4em auto;
    }

    .about-h1 {
        margin-bottom: 2px;
    }

    .about-form {
        width: 100%;
        margin: 0 auto;
    }

    .about-form-group {
        padding-top: .5em;
    }

    .about-label {
        display: none;
    }

    .about-input1, .about-select1 {
        display: none;
    }

    .about-input2, .about-select2 {
        display: block;
        width: 100%;
        border: 1px solid rgb(73, 71, 71);
        border-radius: 10px;
        background: none;
        height: 2.5em;
        padding-left: 13px;
    }

    .about-select2 {
        padding-left: 8px;
        color: rgb(114, 113, 113);
    }

    .about-input2:focus, .about-select2:focus {
        outline: none;
    }

    .about-form-button {
        bottom: 7%;
        right: 6.5rem;
    }

    .about-button {
        padding: 0;
        height: 30px;
    }
}
* {
    box-sizing: border-box;
}

.company-container {
    background-color: #CDE1D6;
    width: 100%;
    padding: 1em 0;
}

.company-box {
    width: 95%;
    position: relative;
    border: 1px solid white;
    border-radius: 22px;
    margin: 2.5rem auto;
    padding: 75px 0;
    min-height: 90vh;
    background: rgba(250, 250, 250, 0.336);
}

.company-flex-header {
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    font-size: .8em;
}

.company-header-item {
    align-self: center;
    min-width: 250px;
}

.company-text-header1 {
    color: #06ad0e;
    opacity: .3;
}

.nav-top-2 img {
    width: 12vw;
    margin-left: 18px;
    cursor: pointer;
}

.company-text-header2 {
    text-align: center; /* Center align the text */
    font-size: 33px; /* Adjust size as needed */
    margin-top: 1%;
    margin-bottom: 1%;
    font-weight: 600;
    color: black; /* Default color for "About Your" */
}

.company-circle1 {
    position: relative;
    left: 50%;
    margin-bottom: 10px;
    border-radius: 50%;
    height: 13px;
    width: 13px;
    background: #7ac903;
    border: 2px solid #06ad0e;
    opacity: .3;
}

.company-circle2 {
    position: relative;
    left: 50%;
    margin-bottom: 10px;
    border-radius: 50%;
    height: 13px;
    width: 13px;
    background: #7ac903;
    border: 2px solid #06ad0e;
}

.company-line {
    position: relative;
    margin-top: -29px;
    margin-left: -108px;
    margin-right: -120px;
    height: 2px;
    width: 10%;
    border: 1px solid #06ad0e;
    opacity: .3;
}

.company-form-inline {
    grid-gap: 1em;
    gap: 1em; 
    width: 100%; 
}

.company-section {
    width: 100%;
}

.company-h1 {
    margin-bottom: -10px;
}

.company-form {
    margin-left: 25%;
    margin-right: 20%;
    margin-top: 2%;
}

.company-form-group {
    padding-top: .8em;
}

.company-form-group label {
    font-weight: 500;
}

.company-label, .company-input1 {
    display: block;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 1px;
}

.company-input1, .company-select1, .company-textarea1 {
    border-radius: 7px;
    border: 1px solid rgb(192, 191, 191);
    font-size: 14px;
}

.company-input1 {
    width: 80%;
    height: 2.8em;
}

.company-select1, .company-textarea1 {
    width: 80%;
    height: 2.8em;
}

.company-textarea1 {
    height: 22vmin;
    resize: none;
}

.company-input2, .company-select2, .company-textarea2 {
    display: none;
}

.company-input1:focus, .company-select1:focus, .company-textarea1:focus {
    outline: none;
}

.company-form-button {
    right: 4em;
    text-align: center;
    height: 60px;
    margin-top: 40px;
    font-size: 14px;
}

.company-button1 {
    border: 1px solid rgb(3, 62, 255);
    color: rgb(0, 4, 250);
    font-weight: 600;
    font-size: 22px;
    background: none;
    border-radius: 7px;
    width: 49%;
    max-width: 220px;
}

.company-button2 {
    background-color: #233242; /* Dark navy blue background */
    color: white; /* White text color */
    font-weight: 600; /* Bold text */
    font-size: 18px; /* Font size */
    border-radius: 10px; /* Rounded corners */
    border: none; /* No border */
    padding: 5px 10px; /* Adjust padding for spacing */
    cursor: pointer; /* Pointer cursor for interaction */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transition: background-color 0.3s ease; /* Smooth hover effect */
}

.company-button2:hover {
    background-color: #1b2a37; /* Slightly darker on hover */
}

.company-button1 + .company-button2 {
    margin-left: 40px;
}


@media only screen and (max-width: 525px) {
    .company-container {
        overflow: hidden;
    }

    .company-flex-header {
        font-size: .5em;
    }

    .company-header-item {
        min-width: 150px;
        position: absolute;
    }

    .company-header-item .company-text-header1 {
        margin-right: 160px;
    }

    .company-header-item .company-text-header2 {
        margin-left: 200px;
    }

    .company-circle1 {
        position: relative;
        height: 8px;
        width: 8px;
        margin-left: -84px;
    }

    .company-circle2 {
        position: relative;
        height: 8px;
        width: 8px;
        margin-left: 88px;
    }

    .company-line {
        margin-top: -20px;
        margin-left: -108px;
    }

    .company-section {
        width: 80%;
        margin: 1em auto;
    }

    .company-h1 {
        margin-bottom: 2px;
        font-weight: 600;
    }

    .company-form-group {
        padding-top: .5em;
    }

    .company-label, .company-input1, .company-select1, .company-textarea1 {
        display: none;
    }

    .company-input2, .company-select2 {
        display: block;
        width: 100%;
        border: 1px solid rgb(73, 71, 71);
        border-radius: 10px;
        background: none;
        height: 2.5em;
        padding-left: 13px;
    }

    .company-select2 {
        padding-left: 8px;
        color: rgb(114, 113, 113);
    }

    .company-textarea2 {
        display: block;
        width: 100%;
        border: 1px solid rgb(73, 71, 71);
        border-radius: 10px;
        background: none;
        height: 9.5em;
        padding: 5px 0 0 13px;
        font-size: 15px;
    }

    .company-input2:focus, .company-select2:focus, .company-textarea2:focus {
        outline: none;
    }

    .company-form-button {
        justify-content: space-around;
        width: 90%;
    }

    .company-button1 {
        font-size: 12px;
        height: 30px;
    }

    .company-button2 {
        font-size: 12px;
        height: 30px;
    }
}

@media only screen and (max-width: 1350px) and (min-width: 530px) {
    .company-form-button {
        justify-content: space-around;
    }
}

@media only screen and (max-width: 600px) {
    .company-container {
        padding: 1rem;
    }

    .company-form {
        margin-left: 0;
        width: 100%;
    }

    .company-input1, 
    .company-select1, 
    .company-textarea1 {
        width: 100%;
        font-size: 16px;
    }

    .company-form-inline {
        flex-direction: column;
        grid-gap: 1rem;
        gap: 1rem;
    }

    .company-form-button {
        width: 100%;
        margin-left: 0;
        text-align: center;
    }

    .company-button2 {
        width: 100%;
        font-size: 18px;
    }

    .nav-top-2 img {
        width: 35%;
        margin: 0 auto;
    }

    .company-text-header2 {
        font-size: 24px;
        text-align: center;
        margin-top: 10%;
    }

    .company-label {
        font-size: 14px;
    }
}
.search-back-button-wrapper {
    position: relative;
    top: 1vh;
    left: 4vw;
    display: inline-flex;
    text-align: center;
    align-self: center;
}

.search-back-button-wrapper img {
    height: 25px;
    width: 30px;
}

.search-back-button > * {
    vertical-align: middle;
}

.search-back-button {
    border: none;
    background: none;
    font-weight: 600;
    font-size: 1.5rem;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    gap: 10px; 
}

.grid-item {
    overflow: hidden;
    background: white;
    position: relative;
    height: 200px;
}

.intern-info {
    display: flex;
    font-size: 0.8rem;
    align-items: center;
    margin-top: 2rem;
}

.intern-info h1 {
    margin-left: .8rem;
}

.button-wrapper {
    display: flex;
    position: absolute;
    justify-content: space-around;
    width: 100%;
    bottom: 10px;
}

.edit-button {
    width: 8vw;
    height: 5vh;
    min-width: 113px;
    min-height: 34px;
    border-radius: 5px;
    font-weight: 500;
    border: 1px solid #035da9;
    color: #035da9;
    background: white;
    font-family: Montserrat;
}

.view-button {
    width: 8vw;
    height: 5vh;
    min-width: 113px;
    min-height: 34px;
    border-radius: 5px;
    background: #035da9;
    color: white;
    font-family: Montserrat;
    border: none;
}


@media (max-width: 1050px) {

    .aboutPage {
        width: 10vw;
    }

    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .grid-item {
        height: 200px;
    }

    .button-wrapper {
        justify-content: center;
        grid-gap: 6vw;
        gap: 6vw;
    }
}


@media (max-width: 640px) {

    .grid-container {
        grid-template-columns: repeat(1, 1fr);
        width: 80%
    }

    .button-wrapper {
        justify-content: center;
        grid-gap: 8vw;
        gap: 8vw;
    }

}

.modal {
    background: rgb(253, 253, 253);
}

.modal-button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.applicant-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
    margin: 0 auto;
}

.left-side-card {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.left-side-card select {
    max-width: 180px;
    color: #035da9;
    padding: 1rem 0 1rem 1rem;
    font-size: 1.5rem;
    margin-left: 3.8rem;
    border: 1px solid rgb(194, 193, 193);
    border-radius: 6px;
}

.contact-method {
    color: rgb(141, 140, 140);
}

.right-side-card {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.right-side-card img {
    width: 20px;
    height: 20px;
}

.right-contact-email {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.right-contact-email a {
    text-decoration: none;
    color: black;
}


.right-contact-telephone {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.right-contact-location {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin-top: -12px;
}


.resume-container {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.resume-container button {
    background: none;
    border: none;
}

.resume-download, .resume-view {
    border: 1px solid black;
    border-radius: 20%;
    padding: 1px;
}
.nav_container{
    background-color: #000000;
    padding: 2.0rem 4vw 2rem;
    width: 100vw;
    z-index: 2;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

@media only screen and (min-width:200px) {
    .hamburger_nav{
        display:none;
    }
    .nav_container .navbar_logo{
        min-width: 148px;
    }
    .nav_container .navbar_logo > img{
        width: 15vw;
        cursor: pointer;
    }
    .nav_left{
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .nav_right{
        display:flex;
        float:right;
        min-width:20vw;
        font-size:17px;
    }
    .nav_right > a{
        text-decoration: none;
        display:flex;
        align-items: center;
    }
    .nav_right > a> div {
        margin-left:0.5vw;
        margin-right: 0.5vw;

    }

    .nav_right > a > div.nav_login{
        background-color: white;
        color: black;
        font-weight: bold; /* Bold text */
        border: none; /* No border */
        font-family: 'Montserrat';
        text-decoration: none;
        font-size: 20px; /* Suitable text size */
        padding: 10px 20px; /* Padding around the text */
        border-radius: 5px;
    }
    .nav_right > a > div.nav_login > p{
        margin-top:0;
        margin-bottom:0;
    }
    .nav_right > a > div.nav_get_started{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1DAF5C;
        color: white;
        font-weight: bold; /* Bold text */
        border: none; /* No border */
        font-family: 'Montserrat';
        text-decoration: none;
        font-size: 20px; /* Suitable text size */
        padding: 10px 20px; /* Padding around the text */
        border-radius: 5px;
    }
    .nav_right > a > div.nav_get_started > p{
        margin-top:0;
        margin-bottom:0;
    }
}
.dropdown {
    background-color: #202020;
    width: 100vw;
    padding: 20px;
}
.dropdown a {
    color: white;
    font-weight: 500;
    text-decoration: none;
}
@media only screen and (max-width:600px) {
    .nav_left, .nav_right{
        display:none;
    }
    .hamburger_nav{
        display:flex;
        justify-content: space-between;
        width:100vw;
        z-index: 101;
    }
    .hamburger_nav > .hamburger_left >.navbar_logo{
        min-width: 148px;
    }
    .hamburger_nav > .hamburger_left > .navbar_logo > img{
        width: 15vw;
    }
    .hamburger_right{
        overflow-x: hidden;
    }
    .inactive{
        display:none;
    }
    .hamburger_logo > .nav_bar{
        height:2px;
        width:25px;
        background-color: white;
        margin-top:5px;
        border-radius: 1px;
    }
    .menu_container{
        position: absolute;
        /* transform-origin: 0% 0%; */
        background-color: white;
        transform: translate(50vw, 0);
        width:50vw;
        height:100vh;
        margin-top:-2.5rem;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }
    .menu_container > ul{
        height:100%;
        width:100%;
        list-style-type: none;
        padding: 0;
    }
    .menu_container > ul> a{
        text-decoration: none;
        width:100%;
    }
    .menu_container > ul > a > li{
        width:100%;
        height:8%;
        background-color: transparent;
        font-size:1.5rem;
        color:black;
        font-weight:700;
        display:flex;
        padding-left:3vw;
        align-items: center;
        
    }
    .menu_container > ul > a > li:hover{
        background-color: rgb(201, 201, 201);
    }
    .active{
        transform: translate(-45vw, 0);
        position:fixed;
    }
    .menu_container > ul > li > .small_menu_top{
        display:flex;
        background-color: #203546;
        width:100%;
        height:7vh;
        
        justify-content: space-between;
    }
    .menu_container > ul > li > .small_menu_top > .small_menu_icon{
        display: flex;
        align-items: center;
    }
    .menu_container > ul > li > .small_menu_top > .small_menu_icon > img{
        /* border:solid 2px green; */
        width:15vw;
    }
    .close_icon{
        width: 25px;
        margin-top: 10px;
        position: relative;
        /* background-color: green; */
        display:flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .close_icon > span{
        top: 0;
        height:2px;
        width:20px;
        position: absolute;
        background-color: white;
    }
    .close_icon > span:nth-child(1){
        transform:rotate(45deg);
    }
    .close_icon > span:nth-child(2){
        transform:rotate(-45deg);
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 100;
    }
    .hamburger_right .dropdown-nav{
        overflow: hidden;
        font-size:0;
        transition: font-size 10s linear;
    }
    .close-inactive{
        display:none;
        max-height:0;
    }
    .dropdown-nav.open-active{
        list-style: none;
        font-weight: 500;
        font-size: 1.25rem;
        padding-left:5vw;
    }
    .open-active  a{
        text-decoration: none;
        color:black;
    }
    .open-active  li{
        height: 5vh;
        width:100%;
        display:flex;
        align-items: center;
    }

    .hamburger_nav .hamburger_right li.li-dropdown-nav{
        display:flex;
        width: 100%;
        justify-content: space-between;
    }
    .hamburger_nav .hamburger_right li > img{
        width: calc(10px + 0.8vw);
        margin-right:2.5vw;
    }
    .hamburger_nav .hamburger_right li .nav-arrow{
        transition: transform 0.3s;
    }
    .hamburger_nav .hamburger_right li .nav-arrow.open{
        transform: rotate(180deg);
    }
    .hamburger_nav .nav-sign-up{
        border-radius: 5px;
        background-color:#213546;
        border:none;
        color:white;
        font-size:17px;
        padding:10px;
        width:100px;
        height:50px;
    }
    .hamburger_right .nav-login{
        border-radius: 5px;
        background-color:#1DAF5C;
        border:none;
        color:black;
        padding:10px;
        width:100px;
        height:50px;
        font-size:17px;
    }
    .hamburger_right .nav-button-container button{
        margin: 10px;
    }
    .hamburger_right .nav-button-container{
        display:flex;
        justify-content: space-evenly;
        width:100%;
        flex-wrap: wrap;
        margin-top:10px;
    }
}

/* 
     .homepage_footer{
      background-color: #213546;
      height: 8vh;
      width:100%;
      display: flex;
      color: rgb(255, 255, 255);
    
    }
    
    .footer_grid{
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%; 
      padding-top:0;
      margin-top:0;
    }
    .footer_nav{
      display: none;
      height:0;
    }
  
    .footer_subfooter{
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content: space-between;
    padding:0 30px;

    }
    .footer_subfooter a, .footer_subfooter p{
      display: inline-block;
      margin: 0 10px;
      color: rgb(255, 255, 255);

    }
    .footer_logo{
      width:120px;

    }
    .footer-right{
      width:50%;
      display: flex;
      justify-content: space-evenly; 
      align-items:center;
      font-size:1rem;
    }
    .logo-container{
      display: flex;
      align-items:center;
      width:40%;
      flex-direction: row;
    }
    .navbar__social{ 
      color:white;
    }
    .footer-social-icons{
     display:flex;
     flex-direction:row;
      width:15vw;
      align-items:center;
 justify-content: space-around;
    }
    .sc-icons{
      font-size:36px;
      height:2.5rem !important;
      width:2.5rem !important;
    }

    .sc-icons-tiktok{
      font-size:30px;
      height:2rem !important;
      width:2.5rem !important;
    }
    .footer-right .noSmall a{
      text-decoration: none;
    }
    @media only screen and (max-width:850px){
      .footer-right .noSmall{
        display:flex;
        flex-direction: column;
        float:left;
      }
    }
    @media only screen and (max-width: 635px)  {
  .footer_subfooter{
    width:100%;
    justify-content:space-between;
    overflow:hidden;
    padding:0;
  }
  .footer-right .noSmall{
    display:none;
  }
  .logo-container{
    width:40%;
    flex-direction:column;
    height:100%;
   justify-content:center;
   margin-right:1rem ;
   margin-left:1rem;
  }
  .logo-container > p {
    margin-top:0 !important;
  }
  .footer-social-icons{
    width:60%;
    justify-content:center;
    margin-right:5px;
  }
  .footer-right{
    width:40%;
    justify-content:space-between;
  }
  .footer-right a {
    text-align:center;
  }
  .footer-social-icons {
    justify-self:flex-end;
    width:100%;
  }
}

@media only screen and (max-width: 335px)  { 
  .logo-container{
    width:25%;
  }
  .footer_logo{
    width:100%;
  }
  .logo-container p{ 
    font-size:.6rem;
  } 
  .footer-social-icons{
    width:40%;
  }

}  */
.homepage_footer{
  width:100vw;
  background-color: #000;
  display:flex;
  margin-top: 5%;
  flex-direction: column;
}
.homepage_footer > .footer-line{
  align-self: center;
  margin-top:30px;
  width:95%;
  background-color:#ECF1F499  ;
  height:1px;
  margin-bottom:5vh;
}
.homepage_footer > .footer-content{
  display:flex;
}
.homepage_footer > .footer-content > .footer-logo-container{
  width:50%;
}
.homepage_footer > .footer-content > .footer-logo-container > .footer_logo{
  width: 350px;
  margin-left: 3vw;
}


.homepage_footer > .footer-content > .footer-content-columns-contain{
  width:50%;
  display:flex;
  justify-content: space-around;
}
.homepage_footer > .footer-content > .footer-content-columns-contain > .footer-content-columns{
  color:white;
}
.homepage_footer > .footer-content > .footer-content-columns-contain > .footer-content-columns > h3{
  font-size:calc(15px + 0.25vw);
  font-weight: 600;
  margin-bottom:15px;
}
.homepage_footer > .footer-content > .footer-content-columns-contain > .footer-content-columns > p > a {
  text-decoration: none;
  color:white;
}
.homepage_footer > .footer-content > .footer-content-columns-contain > .footer-social-icons-small{
  display:none;
}
.homepage_footer > .footer-content > .footer-content-columns-contain > .footer-content-columns > p{
  font-size:calc(11px + 0.25vw);
}
.homepage_footer > .footer-links {
  display:flex;
  width:95%;
  align-self: center;
  color:white;
  margin-top:7vh;
  margin-bottom:5px;
}
.homepage_footer > .footer-links > .footer-doc{
  margin:0;
  padding:0;
  width:70%;
  display: flex;
  font-size:calc(13px + 0.25vw);
  align-items: center;
}

.homepage_footer > .footer-links > .footer-doc > .footer-doc-txt{
  display:flex;
}
.homepage_footer > .footer-links > .footer-doc > .footer-doc-txt > p{
  margin:0;
  padding-left:10px;
}
.homepage_footer > .footer-links > .footer-doc > a {
  text-decoration: none;
  color:white;
  margin-left:35px;
}
.homepage_footer > .footer-links > .footer-social-icons{
  width:30%;
  display: flex;
  justify-content: flex-end;
  color:white;
}
.homepage_footer > .footer-links > .footer-social-icons > a{
  color:white;
  width:50px;
}
.homepage_footer > .footer-links > .footer-social-icons > a > .sc-icons{
  height:2.5rem !important;
  width:2.5rem !important;
}

@media only screen and (max-width:900px){
  .homepage_footer > .footer-content{
    display:flex;
    flex-direction: column;
  }
  .homepage_footer > .footer-content > .footer-logo-container{
    width:100%;
    margin-bottom:25px;
  }
  .homepage_footer > .footer-content > .footer-logo-container > .footer_logo{
    width:35vw;
    min-width:150px;
  }
  .homepage_footer > .footer-content > .footer-content-columns-contain{
    width:100%;
    flex-wrap: wrap;
  }
  .homepage_footer > .footer-content > .footer-content-columns-contain > .footer-content-columns{
    width:50%;
    padding-left:5vw;
  }
  .homepage_footer > .footer-content > .footer-content-columns-contain > .footer-social-icons-small{
    display:flex;
    height:auto;
    align-items: flex-end;
  }
  .homepage_footer > .footer-content > .footer-content-columns-contain > .footer-social-icons-small > a{
    color:white;
    margin-right:19px;
  }
  .homepage_footer > .footer-content > .footer-content-columns-contain > .footer-social-icons-small > a > .sc-icons{
    height:5vw !important;
    width:5vw !important;
    min-height:25px !important;
    min-width:25px !important;
  }
  .homepage_footer > .footer-links > .footer-social-icons{
    display:none;
  }
  .homepage_footer > .footer-links{
    width:100%;
    margin-top:25px;
  }
  .homepage_footer > .footer-links > .footer-doc {
    flex-direction: column;
    width:100%;
    align-items: start;
    margin-bottom:10px;
  }
  .homepage_footer > .footer-links > .footer-doc > .footer-doc-txt{
    padding-left:5vw;
  }
  .homepage_footer > .footer-links > .footer-doc > .footer-doc-txt > p{
    padding-left: 0;
  }
  .homepage_footer > .footer-links > .footer-doc > .footer-doc-txt > .right-text{
    padding-left: 10px;
  }
  .homepage_footer > .footer-links > .footer-doc > a{
    margin-left:0;
    padding-left:5vw;
    font-size: calc(11px + 0.25vw);
    margin-top:5px;
  }
}

@media only screen and (max-width:600px){
  .homepage_footer > .footer-content > .footer-content-columns-contain > .footer-social-icons-small{
    margin-top: 10px;
  }
} 
.faq-box {
    border: 1px solid #B5B5B5;
    background: #FFF;
    padding: 23px;
}

.faq-box.top {
    border-top-left-radius: 12px;   
    border-top-right-radius: 12px;
}

.faq-box.bottom {
    border-bottom-left-radius: 12px;   
    border-bottom-right-radius: 12px;
}

.faq-title {
    cursor: pointer;
    color: #035DA9;
    font-size: 11.55px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.img-arrow{
    width: calc(10px + 0.8vw)
}
.arrow {
    float: right;
    transition: transform 0.3s;
}

.arrow.open {
    transform: rotate(180deg);
}

.faq-content {
    max-height: 0;
    color: #4495d7;
    overflow: hidden;
    transition: max-height 5s linear;
    font-size: 11.55px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.faq-content.open {
    max-height: 500px; 
}
.faq-content.close{
    transition: max-height 0.75s ease-out;
}

@media only screen and (min-width: 900px){
    .faq-box{
        padding: 25px;
        width:100%;
        cursor: pointer;
    }
    .faq-title {
        color: #035DA9;
        font-size: calc(10px + 0.5vw);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .faq-content{
        font-size: calc(5px + 0.5vw);
    }
}
.recruiter-plans{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.plan-container-wrapper{
    padding: 6% 4% 4% 4%;
    flex: 1 1;
    
}

.plan-background-wrapper{
    background-color: #FFFFFFCC; 
    padding: 12% 4% 4% 4%;
    display: flex;
    flex-direction: column;
    grid-gap: 5em;
    gap: 5em;
}

.plan-description-wrapper{
    display: flex;
    flex-direction: column;
    grid-gap: 1.5em;
    gap: 1.5em;
}



/* plan A description styling START*/
.plan-description-box{
    border-radius: 20px;
    background: #DBEDE3;
    padding: 4%;
    text-align: center;
    display: flex;
    flex-direction: column;
    grid-gap: 2em;
    gap: 2em;
}



.plan-description-price-heading{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
}

.plan-price{
    color: #231D4F;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.plan-perhead{
    color: #231D4F;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.plan-description-text{
    color: #000;
    text-align: center;
    font-feature-settings: "kern" off;
    font-kerning: none;
    font-size: 11.55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.plan-header{
    color: #213546;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.56px;
    margin-bottom: 3%;
}

.plan-cta-button{
    border-radius: 8px;
    background: #035DA9;
    color: #FFF;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    padding: 2%;

}

@media only screen and (min-width: 900px) {
    .plan-background-wrapper{
        padding: 5% 8% 6% 8%;
    }
    .plan-description-box{
        text-align: center;
        padding-left: 16%;
        padding-right: 16%;
        
    }
    .plan-description-wrapper{
        display: flex;
        flex-direction: column;     
        align-items: center;
    }
    .plan-header{
        text-align: center;
        color: #000;
        text-align: center;
        font-size: calc(15px + 1.5vw);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 70%;
    }
    .plan-description-price-heading{
        color: #000;
        font-size: calc(7px + 1.5vw);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .plan-price{
        color: #231D4F;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-left: 2%;
    }
    .plan-perhead{
        color: #231D4F;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .plan-description-text{
        color: #000;
        text-align: center;
        font-size: calc( 10px + .5vw);
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 150% */
    }
    .plan-description-text-note{
        color: #000;
        font-size: calc( 5px + .5vw);
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
    }
    .plan-cta-button{
        border-radius: 5px;
        padding: 1%;
        width:200px;
        height:50px;
    }
}
@media only screen and (max-width:700px){
    .plan-cta-button{
        border-radius: 5px;
        padding: 1%;
        width:100px;
        height:35px;
        font-size:12px;
    }
    .plan-description-box{
        grid-gap:1em;
        gap:1em;
    }
}
/* plan A description styling END*/

/* plan Comparison styling START*/
.plan-comparison-wrapper{
    display: flex;
    flex-direction: column;
    grid-gap: 1.5em;
    gap: 1.5em;
}

.comparison-heading{
    border-radius: 20px 20px 0px 0px;
    background: #35495A;
    text-align: center;
    padding: 4%;
}

.comparison-heading > h2{
    color: #FFFFFF;
}

.comparison-content-wrapper{
    padding: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 20px 20px;
    background: #FFF;
}

.comparison-content-wrapper > p{
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}
.plan-comparison-content > li{
    list-style-type: none;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    color: #000;
    font-size: 11.55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4%;
}

.plan-comparison-box-container{
    display: flex;
    flex-direction: column;
    grid-gap: 2em;
    gap: 2em;
}
.plan-A-comparison{
    width:100%

}
.plan-B-comparison{
    width:100%
}

@media only screen and (min-width: 900px){
    .plan-comparison-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .plan-comparison-box-container{
        width: 100%;
        display: flex;
        justify-content: space-around;
        grid-gap: 1em;
        gap: 1em;
        flex-direction: row;
    }
    .comparison-heading button{
        padding: 2%;
    }
    .comparison-content-wrapper > p{
        color: #000;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .plan-comparison-content >li{
        color: #000;
        font-size: calc(10px + 0.5vw);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .plan-A-comparison{
        width:40%;
        /* height:500px; */
    }
    .plan-B-comparison{
        width:40%;
        /* height:500px; */
    }
    .comparison-content-wrapper{
        height:300px;
    }
}
/* plan Comparison styling END*/

/* FAQ styling START*/
@media only screen and (min-width: 900px){
    .plan-faq-wrapper{
        display:flex;
        flex-direction: column;
        align-items: center;
    }
}
/* FAQ styling END*/


/* Book a call styling START*/
.plan-book-call-wrapper{
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.book-a-call-description{
    color: #6D6C75;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width:70vw;
}

.plan-book-call-wrapper .calendly_container{
    text-align: center;
    overflow: hidden;
    display: flex;
}
.plan-book-call-wrapper .calendly_container .calendly_inner_container{
    width: 100vw;
    min-height: 750px;
    position: relative;
}

@media only screen and (min-width: 900px){
    .plan-book-call-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .book-a-call-description{
        color: #6D6C75;
        text-align: center;
        font-size: calc(5px + 0.5vw);
        font-style: normal;
        font-weight: 500;
        line-height: 30px; 
        width:50vw;
    }
}
.plan-button{
    border-radius: 5px;
    background: #035DA9;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    padding: 2%;
    width:130px;
    height:40px;
    margin-top: 10px;
}
/* Book a call styling END*/

.Chatbot_container__2Ufrv {
  position: fixed;
  width: 90vw;
  height: 90vh;
  background-color: #d7ece0;
  left: 50%;
  top: 50px;
  transform: translate(-50%, 0);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 5px;
}


.Chatbot_back__ETADb {
  border: none;
  background-color: transparent;
  align-self: end;
}

@media only screen and (max-width: 900px) {
  .Chatbot_container__2Ufrv {
    width: 100vw;
    height: 100vh;
    top: 0;    
  }
}

.MessageInput_container__oBBC3{
  width:100%;
  height:100px;
  margin-bottom: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.MessageInput_container__oBBC3 input{
  border:1px solid #ccc;
  outline:none;
  width: 100%;
  height:30px;
  border-radius:10px;
  margin:0 auto;
  display:block;
  color:#151515;
  font-family:'open sans', sans-serif;
}

.MessageInput_container__oBBC3 input:focus{
  box-shadow: 0 0 8px rgba(72, 193, 249, .4);
}

.MessageInput_container__oBBC3 ::-webkit-input-placeholder{
  color:rgba(0,0,0,.5);
}

.MessageInput_sendButton__2kYoR {
  position: absolute;
  border-radius: 15px;
  background-color: #213546;
  color: white;
  font-weight: 500;
  padding: 6px 10px;
  left: 100%;
  top: 50%;
  transform: translate(10px, -50%);
}

.MessageInput_inputContainer__3dCXu {
  width:70%;
  height:36px;
  display:flex;
  align-items: center;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .MessageInput_sendButton__2kYoR {
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.UserMessage_container__2Yv9b {
  background-color: #213546;
  color: white;
  padding: 5px;
  max-width: 500px;
  border-radius: 5px;
  align-self: flex-end;
  display: inline-block;
  word-break: break-word;
  margin-bottom: 30px;
}

.UserMessage_container__2Yv9b p {
  margin: 0;
}

.MessagesContainer_container__t4y7f {
  display: flex;
  flex-direction: column;
  padding: 0px 100px;
  height: 80%;
  overflow-y: scroll;
}

.MessagesContainer_logo__3fwaC {
  width: 75px;
  margin-bottom: 50px;
}

.MessagesContainer_greeting__1wUKK {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .MessagesContainer_container__t4y7f {
    padding: 0px 25px;
  }
}

.BotMessage_container__1pSRp {
  align-self: flex-start;
  display: flex;
  word-break: break-word;
  align-items: center;
  margin-bottom: 30px;
}

.BotMessage_text__3D7xE {
  margin: 0;
  background-color: white;
  color: black;
  padding: 5px;
  max-width: 500px;
  border-radius: 5px;
  white-space: pre-wrap;
}

.BotMessage_logo__Ao1oQ {
  width: 40px;
  margin-right: 12px;
}

.Suggestions_container__1MdcN {
  background-color: #f6fbf8;
  border-radius: 10px;
  padding: 5px 10px;
  max-width: 700px;
  width: 100%;
}

.Suggestions_button__Iw586 {
  background: none;
  color: 7b7e7c;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin: 8px 0;
  display: block;
}

.Loader_loaderContainer__3UHHr {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    align-self: flex-start; /* Ensure it aligns with the bot messages */
  }
  
  .Loader_logo__3kT9g {
    width: 40px;
    margin-right: 12px;
  }
  
  .Loader_dots__2TCWY {
    font-size: 24px;
    font-weight: bold;
    color: black;
    display: flex;
  }
  
  .Loader_dots__2TCWY span {
    animation: Loader_blink__m8jpP 1s infinite;
  }
  
  @keyframes Loader_blink__m8jpP {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .Loader_dots__2TCWY span:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .Loader_dots__2TCWY span:nth-child(3) {
    animation-delay: 0.6s;
  }
  
body {
    overflow-x: hidden; /* This will prevent horizontal scrolling */
}

.recruiter_home {
    width:100vw;
    display: flex;
    flex-direction: column;
}

.hero_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #000000; /* Background color set to black */
    color: #FFFFFF; /* Text color set to white */
    box-sizing: border-box; /* Ensures padding is included in the width */
    background-image: radial-gradient(circle at center, rgba(255,255,255,0.1), rgba(0,0,0,0));
    overflow-x: hidden; /* Prevent horizontal overflow */
}

.ai-label {
    display: inline-flex;
    align-items: center;
    background-color: #333333;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 16px;
    position: relative;
    top: 40px;
}

.new-tag {
    font-weight: bold;
    background-color: #444444;
    padding: 2px 6px;
    border-radius: 12px;
    margin-right: 8px;
}

.ai-text {
    color: #cdcdcd; /* Gray color */
    font-weight: 500; /* Medium font weight */
}

.hero_title {
    font-size: 60px; /* You may need to adjust this depending on the viewport */
    padding-left: 20%; /* Adjust this value based on your design requirements */
    padding-right: 20%; /* Adjust this value based on your design requirements */
    margin-top: 5%;
    font-weight: bold;
    font-weight: 600;
    margin-bottom: 20px; /* Space between title and subtitle */
    text-align: center;
    width: 100%; /* Ensures text alignment is consistent with padding */
}

.hero_subtitle {
    font-size: 25px;
    margin-bottom: 40px; /* Space between subtitle and button */
    text-align: center;
    color: #cdcdcd;
    font-weight: 400;
    width: 100%; /* Ensures text alignment is consistent with padding */
}

.demo_request_button {
    background-color: #22C55E; /* Nice green color */
    color: white; /* White text */
    border: none; /* No border */
    padding: 10px 20px; /* Padding around the text */
    font-family: 'Montserrat';
    text-decoration: none;
    font-size: 16px; /* Suitable text size */
    font-weight: bold; /* Bold text */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Cursor indicates clickable */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    margin-top: 10px;
}

.comparison-container {
    color: white;
    margin-top: 5%;
  }
  
  .blocks {
    display: flex;
    justify-content: space-around;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 30px;
  }
  
  .block {
    flex: 1 1;
    border-radius: 10px;
    padding: 29px 60px; /* Wider padding */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    color: white;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .block h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .block .description {
    font-size: 1rem;
    color: #b0b0b0;
  }
  
  .metrics {
    grid-gap: 25px;
    gap: 25px;
    display: flex;
    justify-content: space-between;
  }
  
  .metrics .number {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .status-quo {
    border: 3px solid rgba(255, 255, 255, 0.2);
  }
  
  .with-nora {
    border: 3px solid rgba(0, 255, 0, 0.4);
    background: radial-gradient(circle, rgba(0, 255, 0, 0.2) 0%, transparent 80%);
    position: relative;
  }
  
  .with-nora:before {
    content: ' ';
    position: absolute;
    inset: 0;
    border-radius: 10px;
    background: rgba(0, 255, 0, 0.2);
    filter: blur(15px);
    z-index: -1;
  }


.demo_request_button:hover {
    background-color: #16A34A; /* Slightly darker green on hover for visual feedback */
    color :#cdcdcd;
}

.job_description_button {
    background-color: #213546; /* Nice green color */
    color: white; /* White text */
    border: none; /* No border */
    padding: 10px 20px; /* Padding around the text */
    font-family: 'Montserrat';
    text-decoration: none;
    font-size: 16px; /* Suitable text size */
    font-weight: bold; /* Bold text */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Cursor indicates clickable */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    margin-bottom: 20px;
}

.job_description_button:hover {
    background-color: #456b8d; /* Slightly darker green on hover for visual feedback */
    color :#cdcdcd;
}
.btn-container {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    justify-content: center;
    align-items: center;
}

.email-input-container {
    display: flex;
    grid-gap: 2px;
    gap: 2px;
    align-items: center;
    justify-content: center;
  }
  
  .email-input {
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    /* width: 300px; */
  }
  
  .email-submit-button {
    background-color: #22C55E; /* Green background */
    color: white; /* White text */
    border: none; /* Remove border */
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .email-submit-button:hover {
    background-color: #16A34A; /* Darker green on hover */
  }  

.logo-slider {
    width: 100%;
    overflow-x: hidden;
    margin-top: 7%;
}

.trusted-text {
    color: #FFFFFF;  /* Assuming white text color */
    font-size: 14px;  /* Smaller font size for subtlety */
    text-align: center;  /* Center-align the text */
    font-weight: bold;
    padding: 20px 0;  /* Add some padding above and below for spacing */
    text-transform: uppercase;  /* Uppercase text for emphasis */
    letter-spacing: 1px;  /* Increase letter spacing for readability */
    font-family: 'Montserrat';
}

.logo-track {
    display: flex;
    animation: scroll-logos 20s linear infinite;
}

.logo-item {
    width: 200px; /* Adjust based on your logo size */
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center; /* Center logo horizontally */
    align-items: center; /* Center logo vertically */
}

.logo-item img {
    max-height: 40px; /* Limit logo height */
    max-width: 80px;
    width: auto; /* Maintain aspect ratio */
}

@keyframes scroll-logos {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%); /* Assuming logos fill up at least 200% width */
    }
}

.hiring-stats-container {
    color: #FFFFFF; /* White text */
    text-align: center; /* Center the content */
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    align-items: center;
    justify-content: center;
}

.stats-content {
    display: flex;
    align-items: center; /* Align the text and image vertically */
    justify-content: center; /* Center the content horizontally */
    grid-gap: 20px;
    gap: 20px; /* Add space between text and Google review */
    margin-left: 20%;
    margin-right: 20%;
}

.stats-heading {
    font-size: 24px; /* Adjust based on your design */
    font-weight: bold;
}

.fields-container {
    display: flex;
    justify-content: center; /* Center the fields horizontally */
    grid-gap: 10px;
    gap: 10px; /* Space between fields */
    margin-top: 20px; /* Space above the fields */
    flex-wrap: wrap;
}

.field {
    background-color: #333333; /* Dark grey background */
    color: #FFFFFF; /* White text for contrast */
    padding: 2px 10px; /* Slightly larger padding for a better visual */
    border-radius: 20px; /* More pronounced rounded corners */
    font-size: 14px; /* Slightly smaller font size */
    font-weight: 500; /* Medium font weight */
    margin: 0 5px; /* Margin to space out the fields */
    box-shadow: 0 4px 8px rgba(0,0,0,0.15); /* Subtle shadow for depth */
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for interactions */
}

.field:hover {
    background-color: #555555; /* Slightly lighter grey on hover */
    box-shadow: 0 6px 12px rgba(0,0,0,0.2); /* Increased shadow on hover for a lifting effect */
    cursor: pointer; /* Cursor change to indicate interactivity */
}

.google-review {
    display: flex;
    align-items: center; /* Align the image and text vertically */
    justify-content: center; /* Center this section */
}

.google-review img {
    width: 100px; /* Adjust based on your image size */
    height: auto;
    margin-right: 5px; /* Space between the image and text */
}

.network-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 10%;
    margin-left: 10%;
    color: #FFFFFF;
}

.network-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.network-text {
    margin-right: 5%; /* Space between text and logos */
    flex-basis: 50%;
}

.network-heading {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
}

.network-description {
    font-size: 18px;
    margin-bottom: 20px;
}

.demo_request_button {
    margin-bottom: 20px;
}

.university-partnership {
    flex-basis: 50%;
    max-width: 50vw;
    text-align: center;
}

.trusted-text {
    font-size: 12px;
    font-weight: bold;
}

.university-logos {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.university-slide {
    display: inline-block;
    overflow: hidden;
    animation: scroll-logos 20s infinite linear;
}
@keyframes scroll-logos {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%); /* Adjust based on the total width of the logos */
    }
}

.university-logo-item {
    transition: transform 0.3s ease;
    margin: 0 30px;
    display: inline;
}
.university-logo-item img {
    height: auto; /* Maintain a uniform size for all logos */
    width: 50px;
}

.university-logo-item img:hover {
    transform: scale(1.1);
}

.outsourcing-solution {
    width: 100%;
    text-align: center;
    margin-top: 10%;
    color: #fff; /* White text for visibility */
    display: flex;
    flex-direction: column;
}

.outsourcing-heading {
    font-size: 32px; /* Large font size for section heading */
    margin-bottom: 10px; /* Space between heading and description */
    font-weight: bold;
    text-align: center;
}

.outsourcing-description {
    font-size: 20px; /* Smaller font size for descriptive text */
    margin-bottom: 30px; /* Extra space below the text before next section */
}

.how-it-works-1-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-left: 10%;
    color: #FFFFFF;
}

.how-it-works-1-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.image-interview-styling {
    max-width: 60%;
    margin-top: 5%;
    height: auto;
    display: block; 
    margin-left: auto; 
    margin-right: auto;
}

.image1-styling {
    max-width: 52%;
    height: auto;
    border-radius: 20px; 
    overflow: hidden; 
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); 
}

.image2-styling {
    max-width: 110%;
    height: auto;
}

.image3-styling {
    max-width: 55%;
    height: auto;
    margin-left: -10%;
}

.how-it-works-2-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.how-it-works-1-text {
    margin-right: 5%; 
    width: 70%;
}

.how-it-works-1-heading {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
}

.how-it-works-1-description {
    margin-top: 20px;
    font-size: 16px;
}

.how-it-works-1-description li {
    margin-top: 15px; 
}

.how-it-works-1-partnership {
    width: 100%;
    text-align: center;
}

.how-it-works-2-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7%;
    margin-left: 6%;
    color: #FFFFFF;
}

.how-it-works-2-heading {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
    margin-left: 25%;
    text-align: left;
}

.how-it-works-2-description {
    font-size: 18px;
    margin-bottom: 20px;
    margin-left: 25%;
    width: 50%;
    text-align: left;
}

.how-it-works-2-sub-description {
    font-size: 9px;
    margin-bottom: 20px;
}

.how-it-works-3-description {
    font-size: 18px;
    margin-bottom: 50px;
}

.how-it-works-3-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7%;
    margin-left: 10%;
    color: #FFFFFF;
}

.cta-container {
    display: flex;
    margin-top: 10%;
    /* width: 50%;  */
    padding: 0 30px;
    margin-bottom: 30px;
}

.swiper-pagination-bullet {
    background-color: #FFFFFF !important; /* Inactive bullet color */
    opacity: 1; /* Ensure full opacity */
}

.swiper-pagination-bullet-active {
    background-color: #22C55E !important; /* Active bullet color */
}

.cta-text {
    color: #FFFFFF; /* White color for the text */
    margin-right: 30px; /* Space between text and button */
    font-size: 32px; /* Large font size for section heading */
    margin-bottom: 10px; /* Space between heading and description */
    font-weight: bold;
    text-align: left; /* Ensures text aligns left */
}

.youtube-slider {
    padding: 20px 0;
    background-color: #000; 
    margin-bottom: 30px;
  }
  
  .youtube-slider iframe {
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  }

.carousel {
    width: 90%;
    padding: 0 80px;
}
@media only screen and (max-width: 600px) {
    .btn-container {
        flex-direction: column;
        justify-content: start;
    }

    .blocks {
        display: block; /* Stack the blocks vertically */
    }

    .block {
        margin-bottom: 40px; /* Add spacing between stacked blocks */
    }

    .comparison-container {
        width: 90%;
        margin-top: 15%;
    }

    .logo-track {
        display: flex;
        animation: scroll-logos 10s linear infinite;
    }

    .image1-styling {
        max-width: 100%;
        height: auto;
    }
    
    .image2-styling {
        margin-top: 10%;
        max-width: 110%;
        height: auto;
    }
    
    .image3-styling {
        max-width: 95%;
        height: auto;
        margin-left: 0%;
    }

    .cta-container {
        margin-top: 12%;
        width: 100%; 
        padding: 0 30px;
        margin-bottom: 30px;
        flex-direction: column;
    }
    .how-it-works-3-description {
        width: 100%;
    }
    
    .how-it-works-3-section {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 7%;
        margin-left: 0;
        color: #FFFFFF;
        padding: 0 1.5rem;
    }
    .carousel {
        width: 100%;
        padding: 0 10px;
    }
}

.card {
    background: #FFFFFF;
    border-radius: 20px;
    width: 330px;
    padding: 30px;
    height: 360px;
    position: relative;
    overflow: hidden;
    margin: auto;
}

.card-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: none;
    cursor: pointer;
    z-index: 10;
}

.carousel-button.left {
    left: 10px;
}

.carousel-button.right {
    right: 10px;
}

.founder-image {
    width: 60px;
    height: 60px;
}

.company-logo {
    position: absolute; /* Use absolute positioning within the card */
    top: 3%; /* Adjust this value to push the logo lower */
    right: 15px; /* Adjust this value to move the logo more to the right */
    width: 60px; /* Adjust according to your logo size */
    height: 60px; /* Maintain aspect ratio or adjust as needed */
}

.card-content h3 {
    margin-top: 10px;
    color: #000000;
    font-size: 20px;
    font-weight: bold;
}

.card-content .title {
    color: #555;
    font-size: 14px;
    margin-bottom: 10px;
}

.card-content .quote {
    font-size: 15px;
    color: #000000;
    margin-top: 10px;
    font-weight: 600;
}

.custom-solutions-container {
    color: #fff; /* White text */
    display: flex;
    align-items: center;
    justify-content: space-between; /* Spread out items */
    padding: 20px 50px; /* Add some padding around */
    overflow: hidden; /* Hide overflow */
    margin-left: 10%;
    margin-top: 10%;
}

.custom-solutions-left {
    font-size: 40px; /* Bigger font size for emphasis */
    font-weight: bold;
    max-width: 25%; /* Limit width to wrap the text */
    line-height: 1.2; /* Adjust line height for better readability */
}

.custom-solutions-right {
    font-size: 21px;
    max-width: 50%; /* Allocate more width to this part */
    line-height: 1.5; /* Slightly more spaced lines */
    text-align: left; /* Right align the text */
    margin-right: 15%;
}
@media only screen and (max-width: 600px) {
    .custom-solutions-container {
        margin-left: 0;
        margin-right: 0;
        flex-direction: column;
        padding: 20px 1.5rem;
    }
    .custom-solutions-left {
        width: 100%;
        max-width: 100%;
    }
    
    .custom-solutions-right {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
    }
}

.pricing-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #fff; /* White text for contrast */
    margin-top: 7%;
    margin-left: 2%;
}

.features {
    flex: 1 1;
    margin-right: 20px;
    margin-top: 5%;
    color: #fff; /* Ensures the text is white */
    font-size: 18px; /* Matches the desired text size */
    line-height: 1.2; /* Spacing between lines */
    font-weight: normal; /* Normal font weight for regular text */
}

.features h2 {
    margin-bottom: 45px; /* Space between the header and the first feature */
    font-size: 30px; /* Matches the desired text size */
    font-weight: bold; /* Bold for the header */
}

.features p {
    margin-bottom: 40px;
}

.plans {
    display: flex;
    flex: 2 1; /* Allocates 2 parts of the space to the plans */
    align-items: center; /* Aligns the images vertically */
    justify-content: space-around; /* Adjust spacing as needed */
}

/* Styles for clickable cards */
.clickable-card {
    cursor: pointer;
    transition: transform 0.3s;
}

.clickable-card:hover {
    transform: scale(1.05);
}

.plans img {
    width: 100%; /* Ensure the image takes the full width of the parent div */
    height: auto; /* Maintain aspect ratio */
    margin-right: 0; /* Ensure no extra margin on the right */
}

/* FAQ Section */
.faq-section {
    margin-top: 7%;
    margin-right: 8%; /* Add this line to move it towards the left */
}
  
.faq-item {
    background: #1c1c1c;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden; /* Ensure content is hidden initially */
    max-height: 60px; /* Adjust to the desired closed height */
    transition: max-height 0.3s ease; /* Smooth transition for opening/closing */
  }
  
.faq-item.open {
    background: #2c2c2c;
    max-height: none; /* Allow for dynamic height */
}
  
.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
}
  
.faq-answer {
    margin-top: 10px;
    font-size: 16px;
}
  
.faq-toggle {
    font-size: 18px;
}  

.component_one_container {
    width:100vw;
    min-height:80vh;
    padding: 0 4vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.component_one_container > .c1_img_container {
    position:absolute;
    top:0;
    right:4vw;
    width: 40vw;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
.component_one_container > .c1_img_container > .laptop_img{
    width:80%;
}
.component_one_container .left_side{
    width:50vw;
}
.component_one_container > h1{
    font-size:40px;
    font-weight: 600;
    margin-bottom: 2.5vw;
    word-spacing: 0.5rem;
}
.component_one_container > p {
    font-size: 20px;
    font-weight: 500;
    margin-bottom:2.5vw;
}
.component_one_container a, .component_one_container button{
    text-decoration: none;
    display: inline-block;
}

.component_one_container button, .component_three_container a .plan_button{
    display: flex;
    background-color: #213546;
    border-radius: 5px;
    width: 200px;
    height: 45px;
    margin: 16px 0;
    justify-content: center;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    transition: 0.2s all;
}

.component_one_container a .outline-button {
    display: flex;
    border: 3px solid #213546;
    border-radius: 5px;
    width: 200px;
    height: 45px;
    margin: 16px 0;
    justify-content: center;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    transition: 0.2s all;
    background-color: transparent;
}

.component_one_container a .outline-button p{
    margin: auto;
    color: #213546;
    font-size: 16px;
    font-weight: 500;
}

.sign_up_button_container{
    width:100%;
    display:flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.component_one_container button:active, .component_three_container a .plan_button:active{
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}
.component_one_container button p{
    margin: auto;
    color: white;
    font-size: 16px;
    font-weight: 600;
}
.component_three_container a .plan_button p{
    margin: auto;
    color: white;
    font-size: 1.6rem;
    font-weight: 600;
}
.component_three_container a .plan_button{
    width:200px;
    height:60px;
}
.component_three_container a .plan_button p{
    font-size: 17px;
}
.component_three_container a{
    text-decoration: none;
}


@media only screen and (max-width: 900px) {
    .plans {
        flex-direction: column;
        width: 100%;
        grid-gap: 16px;
        gap: 16px;
    }
    
    .plans img {
        width: 100%; 
        margin-right: 0px;
    }

    .pricing-container {
        flex-direction: column;
        margin-left: 0;
        padding: 0 1.5rem;
        grid-gap: 16px;
        gap: 16px;
    }
    .features h2 {
        margin-bottom: 10px;
    }
    .features p {
        margin-bottom: 0;
    }
    .component_one_container h1{
        margin-top:5vh;
        font-size: 35px;
    }
    .component_one_container p{
        margin-top: 2.5vh;
        font-size: 20px;
    }
    .component_one_container{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .component_one_container > .c1_img_container{
        position: relative;
        display:flex;
        justify-content: center;
        align-items: center;
        right:0;
        width:80vw;
    }
    .component_one_container .left_side{
        width:100%;
        text-align: center;
    }
    .component_one_container .sign_up_button_container{
        width:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        grid-gap: 0px;
        gap: 0px;
    }
    .component_one_container button{

        width: 171px;
        height: 48px;
        margin: 16px 0;
    }
    .component_one_container button p{
        font-size:16px;
    }
    .component_one_container a .outline-button{
        margin: 0;
        width: 171px;
        height: 48px;
    }
    .component_one_container a .outline-button p{
        font-size:16px;
    }
}

@media only screen and (max-width: 600px) {
    .component_one_container h1 {
        font-size: 25px;
    }

    .component_one_container p {
        font-size: 20px;
    }
    .component_one_container{
        min-height: auto;
        padding-top:2.5vh;
        padding-bottom:2.5vh;
    }
    .component_one_container button {
        width: 131px;
        height: 43px;
        margin: 16px 0;
    }
    .component_one_container button p {
        font-size: 12px;
    }
    .component_one_container a .outline-button {
        width: 131px;
        height: 43px;
    }
    .component_one_container a .outline-button p {
        font-size: 12px;
    }
    .hamburger_nav > .hamburger_left > .navbar_logo > img{
        width:calc(20vw + 10px);
    }
}


.component_two_container{
    min-height: 50vh;
    width:100vw;
    padding: 10px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:#7FB89880;
    /*background-color: #213546;*/
}

.component_two_container .text_container{
    width:80vw;
    color:white;
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.component_two_container .text_container h1{
    font-size: 35px;
    font-weight: 600;
    word-spacing: 0.25em;
}
.component_two_container .text_container p{
    font-size: 25px;
}
.component_two_container .image_container{
    width:90vw;
    display: flex;
}

.component_two_container .image_grid_container{
    width:90vw;
    display:grid;
    grid-template-rows:1fr;
    grid-template-columns:repeat(5,1fr);
    grid-gap:10px;
}
.component_two_container .image_grid_container .grid-item{
    background-color: transparent;
    padding: 10px;
    display:flex;
    justify-content: center;
    align-items: center;
    
}
.component_two_container .image_grid_container .grid-item img{
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

@media only screen and (max-width:900px){
    .component_two_container .text_container h1{
        font-size: 30px;
        font-weight: 600;
        word-spacing: 0.25em;
    }
    .component_two_container .image_grid_container {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas: 
        'img1 img1 img2 img2 img3 img3'
        '. img4 img4 img5 img5 .';
        grid-gap:1em;
        grid-row-gap: 0.25em;
        row-gap: 0.25em;
    }
    .image_grid_container .one{
        grid-area: img1;
    }
    .image_grid_container .two{
        grid-area: img2;
    }
    .image_grid_container .three{
        grid-area: img3;
    }
    .image_grid_container .four{
        grid-area: img4;
    }
    .image_grid_container .five{
        grid-area: img5;
    }
}
@media only screen and (max-width:600px){
    .component_two_container .text_container h1{
        font-size: 25px;
        font-weight: 600;
        word-spacing: 0.25em;
    }
    .component_two_container .text_container p{
        font-size: 15px;
    }
    .faq-section {
        margin-right: 0;
        padding: 0 1.5rem;
    }
}


.component_three_container{
    min-height: 70vh;
    width:100vw;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.component_three_container .header_text, .component_three_container .bottom_text{
    width:80vw;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top:5%;
    text-align: center;
}
.component_three_container .header_text h1{
    font-size:40px;
    font-weight:600;
    /* word-spacing: 10px; */
}
.component_three_container .header_text p, .component_three_container .bottom_text p{
    font-size:20px;
    text-align: center;
    line-height: 30.5px;
    font-weight: 400;
    /* word-spacing:10px; */
    margin-top:10px;
}
.component_three_container .bottom_text{
    margin-bottom:70px;
}
.component_three_container .card_container{
    display:flex;
    justify-content: space-evenly;
    width:100vw;
    align-items: center;

    margin-top:30px;
}
.component_three_container .card_container .left_card, .component_three_container .card_container .right_card{
    height:530px;
    width: 430px;
    background-color:#8EC7A666;
    color:#231D4F;
    display:flex;
    flex-direction: column;
    border-radius: 30px;
}
.component_three_container .card_container .plan {
    font-size:25px;
    font-weight: 500;
    margin-bottom: 0;
    margin-left: 40px;
    margin-top: 50px;
}
.component_three_container .card_container .price {
    font-size:35px;
    font-weight: 700;
    margin-left: 40px;
    margin-bottom:1px;
}
.component_three_container .list{
    margin-left: 40px;
    font-weight:500;
    font-size:15px;
    color:black;
}
.checklist_container{
    display:flex;
    margin-left: 40px;
    margin-bottom:10px;
    color:black;
}

.checklist_container p{
    margin-bottom: 0;
    margin-left: 10px;
    font-weight:500;
    font-size: 15px;
}
.component_three_container .card_container .card_button_container{
    width:100%;
    display:flex;
    justify-content: center;
}
.component_three_container .card_container .left_card .card_button_container{
    margin-top: 20px;
}
.component_three_container .card_container .right_card .card_button_container{
    margin-top: 20px;
}
.component_three_container .card_container .card_button_container a{
    text-decoration: none;
}
.component_three_container .card_container .card_button_container a .sign_up{
    display: flex;
    background-color: #1DAF5C;
    border-radius: 5px;
    width: 200px;
    height: 48px;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    transition: 0.2s all;
}
.component_three_container .card_container .card_button_container a .sign_up:active{
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}
.component_three_container .card_container .card_button_container a .sign_up p{
    margin: auto;
    color: white;
    font-size: 16px;
    font-weight: 600;
}
.component_three_container .card_container .caption{
    margin-left: 40px;
    margin-top: 20px;
    font-size: 15px;
    font-weight:400;
    margin-right:10px;
}

@media only screen and (max-width:900px){
    .component_three_container .card_container{
        display:flex;
        justify-content: space-evenly;
        width:80vw;
        align-items: center;
        flex-direction: column;
        margin-top:30px;
    }
    .component_three_container .header_text h1{
        font-size: 35px;
    }
    .component_three_container .bottom_text{
        display:none;
    }
    .component_three_container .card_container .left_card, .component_three_container .card_container .right_card{
        margin-bottom:50px;
    }
}

@media only screen and (max-width:600px){
    
    .component_three_container .header_text h1{
        font-size:25px;
    }
    .component_three_container .header_text p{
        font-size:15px;
        min-width:90vw;
    }
    .component_three_container .card_container .left_card, .component_three_container .card_container .right_card{
        height:410px;
        width: 280px;
    }
    .component_three_container .card_container .plan{
        font-size: 15px;
        margin-left: 30px;
        margin-top: 20px;
    }
    .component_three_container .card_container .price {
        font-size: 17px;
        margin-left: 30px;
    }
    .component_three_container .list{
        font-size:11px;
        margin-left:30px;
    }
    .checklist_container{
        margin-left:30px;
    }
    .checklist_container p{
        font-size: 13px;
    }
    .component_three_container .card_container .left_card .card_button_container{
        margin-top: 25px;
    }
    .component_three_container .card_container .right_card .card_button_container{
        margin-top: 10px;
    }
    .component_three_container .card_container .card_button_container a .sign_up{
        width:150px;
        height:40px;
    }
    .component_three_container .card_container .card_button_container a .sign_up p{
        font-size:13px;
    }
    .component_three_container .card_container .caption{
        margin-left:30px;
        font-size: 12px;
    }

}

.component_four_container{
    width:100vw;
    display:flex;
    min-height:50vh;
    flex-direction: column;
    align-items: center;
}
.component_four_container .testimonial_header{
    font-size:40px;
    font-weight:600;
    margin-top: 5vh;
    margin-bottom:5vh;
}
.component_four_container .carousel_wrapper{
    position:relative;
    margin-bottom: 5vh;
}
.component_four_container .carousel_wrapper .carousel{
    width: 84vw;
    position: unset;
}
.component_four_container .item{
    display: flex;
    flex-direction: column;
    height: 250px;
    background-color: white;
    margin: 0 16px;
    border-radius: 8px;
    text-align: center;
    justify-content: center;
    font-weight: 400;
    padding:30px;
    height:100%;
}
.component_four_container .react-multi-carousel-list .react-multiple-carousel__arrow--left{
    left: -5vw;
}
.component_four_container .react-multi-carousel-list .react-multiple-carousel__arrow--right{
    right: -5vw;
}
.component_four_container .react-multi-carousel-list .react-multiple-carousel__arrow{
    background: none;
}
.component_four_container .react-multi-carousel-item{
    height:auto;
}
@media only screen and (max-width:900px){
    .component_four_container .testimonial_header{
        font-size: 35px;
    }
}
@media only screen and (max-width:600px){
    .component_four_container .testimonial_header{
        font-size: 25px;
    }
}
.react-multi-carousel-list .react-multi-carousel-dot button {
    background-color: #8EC7A6;
    border: none;
    position: relative;
    bottom: -3.5vh;
}
.react-multi-carousel-list .react-multi-carousel-dot--active button{
    background-color: #1DAF5C;
}

.component_five_container {
    width: 100vw;
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    padding-bottom: 3%;
}

.component_five_container .calendly_header {
    margin-top: 6%;
    font-size: 32px;
    font-weight: bold;
    color: white;
    width: 75vw;
    text-align: center;
}

.component_five_container .calendly_subtext {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    color:white;
    width: 75vw;
    text-align: center;
}

.component_five_container .calendly_inner_container{
    width: 100vw;
    min-height: 750px;
    position: relative;
}

@media only screen and (max-width: 1010px) {
    .component_five_container .calendly_inner_container {
        width:100vw;
        height:1200px;
        overflow: hidden;
    }
}
@media only screen and (max-width:900px){
    .component_five_container .calendly_header{
        font-size: 30px;
    }
}
@media only screen and (max-width: 850px) {
    .component_five_container .calendly_inner_container {
        width:100vw;
        height:1200px;
        /* min-height:1100px; */
        overflow: hidden;
    }
}
@media only screen and (max-width: 650px){
    .component_five_container .calendly_inner_container{
        height:1100px;
    }
}
@media only screen and (max-width: 600px){
    .component_five_container .calendly_header{
        font-size: 30px;
        margin-top: 5rem;
        width: 85vw;
    }

    .component_five_container .calendly_subtext{
        width: 85vw;
        margin-top: 2rem;
        margin-bottom: 3rem;
    }
    
}
@media only screen and (max-width: 500px) {
    .component_five_container .calendly_inner_container {
        width: 90vw;
        height:1100px;
        overflow: hidden;
    }
}

@media only screen and (max-width: 1100px) {
    .how-it-works-2-content {
        flex-direction: column;
    }
    .how-it-works-2-description {
        width: 100%;
        margin-left: 0;
    }
    .how-it-works-2-heading {
        margin-left: 0;
    }
}

@media only screen and (max-width:600px){

    .how-it-works-1-content {
        flex-direction: column;
    }

    .how-it-works-1-section {
        margin-left: 0;
        padding: 0 1.5rem;
    }

    .how-it-works-1-partnership img {
        width: 100%;    
    }

    .how-it-works-1-text {
        margin-right: 0; /* Space between text and logos */
        margin-top: 4rem;
        width: 100%;
    }

    .how-it-works-1-heading {
        font-size: 28px;
        text-align: center;
    }
    
    .how-it-works-1-description {
        width: 100%;
        margin-bottom: 3rem;
    }

    .how-it-works-2-section {
        margin-left: 0;
        padding: 0 1.5rem;
    }

    .how-it-works-2-sub-description {
        margin-bottom: 5rem;
    }

    .how-it-works-2-heading {
        font-size: 30px;
        margin-top: 4rem;
    }

    .network-section {
        margin-left: 0;
        padding: 16px;
        width: 100%;
        display: block;
    }

    .network-content {
        flex-direction: column;
        margin-left: 1rem;
    }

    .network-text {
        margin-right: 0; /* Space between text and logos */
        flex-basis: 50%;
        margin-top: 4rem;
    }

    .university-partnership {
        flex-basis: 100%;
        max-width: 100vw;
    }

    .stats-content {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        margin-top: 4rem;
    }

    .trusted-text {
        margin-top: 2rem;
    }   

    .hero_title {
        font-size: 40px; /* You may need to adjust this depending on the viewport */
        padding: 0 1rem;
        margin-top: 6rem;
        margin-bottom: 3rem;
    }

    .hero_subtitle {
        font-size: 18px;
        margin-bottom: 4rem;
    }

    .outsourcing-solution {
        text-align: left;
        padding: 0 1.5rem;
    }

    .outsourcing-heading {
        font-size: 28px;
        margin-top: 3rem;
        margin-bottom: 1rem;
    }

    .cta-text {
        margin-top: 5rem;
        margin-bottom: 2rem;
    }

    .custom-solutions-left {
        margin-top: 4rem;
        margin-bottom: 2rem;
    }

    .faq-section {
        margin-top: 7rem;
    }
} 

.press_page{
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: 'Montserrat';
}
.header_section{
    width:90vw;
    min-height:25vh;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.bottom_section{
    width:90vw;
    min-height:10vh;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.header_section > h1{
    font-weight:600;
    font-size: 50px;
}
.header_section > h4, .bottom_section > h4{
    font-weight: 500;
}
.press_page .press_content_container{
    min-width:90vw;
    display:grid;
    grid-template-rows:2fr;
    grid-template-columns:repeat(3,1fr);
    grid-row-gap: 50px;
    row-gap: 50px;
    grid-column-gap: 50px;
    column-gap: 50px;
    margin-bottom: 50px;
    /* border:solid palegoldenrod 2px; */
}
.press_page .press_content_container .img_container{
    width:29.3vw;
    height:40vh;
    /* border:solid 2px green; */
}
.press_page .press_content_container .img_container img{
    object-fit: fill;
    max-width: 100%;
    height:90%;
    /* width:100%; */
    /* border: solid 5px black; */
}
.press_page .press_content_container .img_container figcaption{
    font-size:20px;
    font-weight: 500;
}
.press_page .press_content_container .grid_item{
    background-color: transparent;
    width:29.3vw;
    /* border:solid 2px palegreen; */
}
.press_page .press_content_container .grid_item p{
    font-weight: 500;
    font-size:30px;
}
.press_page .press_content_container a {
    text-decoration: none;
}
.press_page .press_content_container a .button{
    display: flex;
    background-color: #213546;
    border-radius: 5px;
    width: 220px;
    height: 55px;
    margin: 16px 0;
    justify-content: center;
}
.press_page .press_content_container a .button p{
    margin: auto;
    color: white;
    font-size: 20px;
    font-weight: 600;
}

@media only screen and (max-width:1080px){
    .press_page .press_content_container{
        grid-template-rows:2fr;
        grid-template-columns:repeat(2,1fr);
        /* border:solid palegoldenrod 2px; */
    }
    .press_page .press_content_container .grid_item{
        width:45vw;
        /* border:solid 2px palegreen; */
    }
    .press_page .press_content_container .img_container{
        width:45vw;
        height:35vh;
        /* border:solid 2px green; */
    }
}
@media only screen and (max-width:650px){
    .press_page .press_content_container{
        grid-template-rows:4fr;
        grid-template-columns:repeat(1,1fr);
        /* border:solid palegoldenrod 2px; */
    }
    .press_page .press_content_container .grid_item{
        min-width:100%;
        /* border:solid 2px palegreen; */
    }
    .press_page .press_content_container .img_container{
        width:100%;
        height:35vh;
        /* border:solid 2px green; */
    }
    .header_section > h1{
        font-size: 40px;
    }
    .header_section > h4, .bottom_section > h4{
        font-size:20px;
    }
    .bottom_section{
        margin-bottom:20px;
    }
    .press_page .press_content_container .img_container figcaption{
        font-size:15px;
    }
    .press_page .press_content_container .grid_item p{
        font-size:25px;
    }
    .press_page .press_content_container a .button{
        width: 150px;
        height: 45px;
    }
    .press_page .press_content_container a .button p{
        font-size: 15px;
        font-weight: 600;
    }
    .press_page .press_content_container .img_container img{
        object-fit: fill;
        width: 100%;
    }
}
@media only screen and (max-width:400px){
    .header_section > h4, .bottom_section > h4{
        font-size:15px;
    }
}

  .submitted-msg {
      width: 50%;
      text-align: center;
      margin: auto;
    }
  
  .contact-form-title {
      width: 90%;
      min-width: 28rem;
      max-width: 55rem;
      text-align: center;
      margin: auto;
      margin-top: 2rem;
      margin-bottom: 2rem;
      color: black;
      font-weight: 400;
  }
  
  .contact-form {
      width: 90%;
      min-width: 28rem;
      max-width: 55rem;
      text-align: center;
      margin: auto;
      color: black;
      border: 2px solid white;
      padding: 2rem 3.5rem;
      background-color: rgba(255, 255, 255, 0.54);
      border-radius: 25px;
      font-weight: 400;
    }
    
    .contact-form__input {
      border: none;
      border-radius: 5px !important;
    }
    
    .contact-form__text-area {
      font-size: 1.25rem;
      width: 100%;
      border-radius: 5px !important;
    }
    
    .contact-form__dropdown-btn {
      background-color: white;
      color: rgb(96, 96, 96);
      border-radius: 5px !important;
    }
    
    ::placeholder {
      color: black;
      opacity: 1;
    }
    
    #contact-form__btn {
      font-family: 'Montserrat';
      background-color: rgba(3, 93, 169, 1);
      color: white;
      font-weight: 700;
      font-size: 1.25rem;
      padding: .7rem 3rem;
      border-radius: 5px;
      border: none;
      margin-left: -.75rem;
    }
    
    input.form-control {
      height:3rem !important;
    }
  
    .schedule-title {
      margin: 2rem auto 2rem auto;
      text-align: center;
      color: black;
      font-weight: 400;
    }
  
    .schedule-form {
      width: 90%;
      margin: 2rem auto 7rem auto;
      color: black;
      display: flex;
      justify-content: space-around;
      border: 2px solid white;
      background-color: rgba(255, 255, 255, 0.54);
      border-radius: 25px;
    }
  
    .schedule-description {
      width: 30rem;
      font-weight: 600;
      font-size: x-large;
      margin: 4rem 0 0 2rem;
    }

    .calendly_inner_container {
      width: 60vw;
      min-height: 750px;
      position: relative;
    }
  
    @media only screen and (max-width: 1356px) {
      .schedule-form {
          flex-direction: column;
          justify-content: start;
          align-items: center;
      }
  
      .schedule-description {
          width: 80%;
          font-weight: 600;
          font-size: large;
          margin: 0 auto 0 atuo;
        }

        .calendly_inner_container {
          width: 90%;
          height: 1200px;
          overflow: hidden;
        }
    }
    
    @media only screen and (max-width: 576px) {
      .contact-form__btn-div {
        justify-content: center;
      }
    
      .contact-form__input, .contact-form__text-area {
        font-size: 3vw !important;
      }
    
      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
       font-size: 3vw !important;
      }
    
      .contact-form__dropdown-btn {
        font-size: 3vw;
        padding-top: .8rem;
        padding-bottom: .8rem;
      }
    
      #contact-form__btn {
        font-weight: 700;
        font-size: 1rem;
        padding: .7rem 3rem;
        border-radius: 5px;
        border: none;
        margin-left: -.75rem;
      }
  
      .schedule-form {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
      }
  
      .schedule-description {
          width: 90%;
          font-size: medium;
          margin-top: 2rem;
      }

      .calendly_inner_container {
        width: 90%;
        height: 1200px;
        overflow: hidden;
      }
    }
    
    /* *************** none of styling below is used in contact form*/

    button
    {
      overflow: visible;
    }
    
    ::placeholder
    {
      color:black;
      opacity: 0.6;
    }
    
    
    .contact-form .btn input {
      margin-top: 10px;
    }
    
.blog-card{
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    border-radius: 15px;
    width: 400px;
    height: 550px;
}
.w-full{
    width: 100%;
}

.card-description{
    font-weight: 400;
    margin-bottom: 0;
}

.w-50{
    width: 50%;
}
.h-500{
    height: 500px;
}

.h-50{
    height: 50%;
}
.object-fit-contain{
    object-fit: contain;
}
.rounded-lg{
    border-radius: 10px;
}

.h-full{
    height: 100%;
}

.h-screen{
    height: 100%;
}

.h-550{
    height: 550px;
}

.p-60{
    padding: 60px;
}

.p-40{
    padding: 40px;
}

.p-30{
    padding: 30px;
}

.p-screen{
    padding: 150px;
    padding-top: 60px;
}

.background-1{
    background-image: url(/static/media/background.f247d6fb.svg);
    background-size: cover;
}

.text-center{
    text-align: center;
}

.font-bold{
    font-weight: 600;
}

.justify-center{
    justify-content: center;
}


.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-wrap{
    flex-wrap: wrap;
}

.card-title{
    font-size: 18px;
    font-weight: 600;
}

.w-400{
    width: 400px;
}

.items-center{
    align-items: center;
}

.outline-none{
    outline: none;
}

.rounded-lg{
    border-radius: 10px;
}

.p-1{
    padding: 1.5rem;
}

.border-grey{
    border: 1px solid #d1d1d1;
}

.mb-5{
    margin-bottom: 24px;
}

.mb-10{
    margin-bottom: 40px;
}

.mt-100{
    margin-top: 100px;
}

.justify-evenly{
    justify-content: space-evenly;
}

.rounded-xl{
    border-radius: 15px;
}

.bg-white{
    background-color: rgb(218, 218, 218);
}

.rounded-2xl{
    border-radius: 20px;
}

.read-more-button{
    background-color: #213546;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    width: 150px;
    border: 1px solid #d1d1d1;
    font-weight: 600;

    &:hover{
        background-color: #172531;
    }
}


.featured-desc{
    padding-top: 20px;
    font-size: 15px;
    font-weight: 400;
}
.mt-auto {
    margin-top: auto;
}
.pt-0{
    padding-top: 0px;
}
@media screen and (max-width: 860px){
    .section-p{
        padding: 30px !important;
        padding-top: 0px !important;
    }
    .mobile-hidden{
        display: none;
    }

    .mobile-post{
        padding: 30px;
    }

    .mobile-flex{
        display: flex;
        flex-direction: column !important;
    }

    .mobile-header{
        font-size: 24px;
    }

    .mobile-desc{
    padding-top: 20px;
    font-size: 15px;
    font-weight: 400;
    }
    .mobile-width{
        width: 100%;
    }
    .mobile-height{
        height: 750px;
    }
    
}
@media screen and (max-width: 1288px){
    .mobile-p{
        padding: 30px;
    }

    .mobile-button{
        padding: 20px;
        width: 100%;
    }

    .mobile-width{
        width: 100% !important;
    }
}

@media screen and (max-width: 1500px) {
    .mobile-width {
        width: 100%;
    }
    .w-400{
        width: 100%;
    }
}

@media (min-width: 860px) and (max-width: 1500px) {

    .blog-card-md{
        display: flex !important;
        flex-direction: row !important;
        margin-bottom: 40px;
        border-radius: xx-large;
        padding: 0px !important; 
    }

    .p-30{
        padding: 60px !important;
    }  
    .md-desc{
    padding-top: 20px;
    font-size: 15px;
    font-weight: 400;
    }
    .blog-card-md-section{
        padding: 60px;
        height: 100% !important;
        width: 50%;
        justify-content: center !important;
    } 
    .md-title{
        font-size: 2.5rem;
    }
    .md-button{
        justify-content: start !important;
        margin-top: 0 !important;
    }
}

.w-full{
    width: 100%;
}

.h-full{
    height: 100%;
}

.h-screen{
    height: 100%;
}

.p-60{
    padding: 60px;
}

.p-40{
    padding: 40px;
}

.p-30{
    padding: 30px;
}

.p-screen{
    padding: 150px;
    padding-top: 60px;
}

.background-1{
    background-image: url(/static/media/background.f247d6fb.svg);
    background-size: cover;
}

.text-center{
    text-align: center;
}

.font-bold{
    font-weight: 600;
}

.justify-center{
    justify-content: center;
}

.justify-between{
    justify-content: space-between;
}


.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col{
    display: flex;
    flex-direction: column;
}

.flex-wrap{
    flex-wrap: wrap;
}

.w-400{
    width: 400px;
}

.items-center{
    align-items: center;
}

.outline-none{
    outline: none;
}

.rounded-lg{
    border-radius: 10px;
}

.p-1{
    padding: 1.5rem;
}

.border-grey{
    border: 1px solid #d1d1d1;
}

.mb-5{
    margin-bottom: 24px;
}

.mb-10{
    margin-bottom: 40px;
}

.mt-100{
    margin-top: 100px;
}

.justify-evenly{
    justify-content: space-evenly;
}

.rounded-xl{
    border-radius: 15px;
}

.bg-white{
    background-color: rgb(218, 218, 218);
}

.rounded-2xl{
    border-radius: 20px;
}

.grid-cols{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust the minimum and maximum width as needed */
        grid-gap: 16px;
        gap: 16px; /* Adjust the gap between items as needed */
        justify-content: space-between;
}

.read-more-button{
    background-color: #213546;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    width: 150px;
    border: 1px solid #d1d1d1;
    font-weight: 600;

    &:hover{
        background-color: #172531;
    }
}

@media screen and (max-width: 860px){
    .mobile-hidden{
        display: none;
    }

    .mobile-post{
        padding: 30px;
        height: 50%;
    }

    .mobile-flex{
        display: flex;
        flex-direction: column !important;
    }

    .mobile-header{
        font-size: 24px;
    }

    .mobile-desc{
        padding-top: 10px;
    }

    .mobile-width{
        width: 100%;
    }

    .mobile-height{
        height: 700px;
    }  
}
@media screen and (max-width: 1288px){
    .mobile-card{
        width: 100%;
        height: 700px;
    }
}
.featured-desc{
    padding-top: 20px;
    font-size: 15px;
    font-weight: 400;
}
@media screen and (max-width: 860px){
    .mobile-hidden{
        display: none;
    }

    .mobile-post{
        padding: 30px;
    }

    .mobile-flex{
        display: flex;
        flex-direction: column !important;
    }

    .mobile-header{
        font-size: 24px;
    }

    .mobile-desc{
        padding-top: 10px;
    }

    .m-width{
        width: 100%;
    
    }
}

@media screen and (max-width: 1288px){
    .mobile-p{
        padding: 30px;
    }
    .mobile-button{
        padding: 20px;
        width: 100%;
    }
}
.blog-item{
    margin-bottom: 40px;
}
.blog-item a{
box-sizing: unset !important;
color: black;
text-decoration: none;
}
.pagination {
    display: flex;
    list-style-type: none;
    justify-content: center;
    place-items: center;
  
  
    span{
      cursor: pointer;
    }
    
  
    .pagination-item {
      padding: 0 12px;
      text-align: center;
      margin: auto 4px;
      color: rgba(0, 0, 0, 0.87);
      display: flex;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.01071em;
      border-radius: 16px;
      line-height: 1.43;
      font-size: 13px;
      min-width: 32px;
  
      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }
  
      &.selected {
        background-color: rgba(0, 0, 0, 0.08);
      }
  
      .arrow {
        &::before {
          position: relative;
          /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
          content: '';
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid rgba(0, 0, 0, 0.87);
          border-top: 0.12em solid rgba(0, 0, 0, 0.87);
        }
  
        &.left {
          transform: rotate(-135deg) translate(-50%);
        }
  
        &.right {
          transform: rotate(45deg);
        }
      }
  
      &.disabled {
        pointer-events: none;
  
        .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }
  
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }
.loader3{
    position: relative;
    z-index: 100;
    display: flex;
    height: 100%;
    width: 100%;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    text-align: center;
    justify-content: center;
    align-items: center;
}
.loader3::-webkit-scrollbar {
    display: none;
  }
.loader2 {
    border: 8px solid #f3f3f300;
    border-radius: 50%;
    border-top: 8px solid #4fc984;
    width: 100px;
    height: 100px; 
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.w-full{
    width: 100%;
}

.h-screen{
    height: 100vh;
}

.p-40{
    padding: 40px;
}

.p-screen{
    padding: 100px;
    padding-top: 60px;
}

.background-1{
    background-image: url(/static/media/background.f247d6fb.svg);
    background-size: cover;
}

.text-center{
    text-align: center;
}

.font-bold{
    font-weight: 600;
}

.justify-center{
    justify-content: center;
}


.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col{
    display: flex;
    flex-direction: column;
}

.w-400{
    width: 400px;
}

.items-center{
    align-items: center;
}

.outline-none{
    outline: none;
}

.rounded-lg{
    border-radius: 10px;
}

.p-1{
    padding: 1.5rem;
}

.border-grey{
    border: 1px solid #d1d1d1;
}

.mb-5{
    margin-bottom: 24px;
}

.justify-evenly{
    justify-content: space-evenly;
}



@media screen and (max-width: 768px){
    .mobile-hidden{
        display: none;
    }

    .mobile-p{
        padding: 40px !important;
    }

    .mobile-flex{
        display: flex;
        flex-direction: column;
    }

}

@media screen and (max-width: 1380px){
   .mobile-padding {
    padding: 0;
    padding-left: 0px !important;
    padding-right: 0px !important;
   }

   .glass-container{
    background-color: rgba(255, 255, 255, 0.30); 
    -webkit-backdrop-filter: blur(5px); 
            backdrop-filter: blur(5px);
    margin-top:0px !important;
    margin-bottom: 0px !important;
    border-radius: 10px;
    border: none !important;
   }

   .gap{
    grid-gap: 50px;
    gap: 50px;
   }
}


.blog-details{
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rounded-img{
    border-radius: 25px;
    border-color: black;
}

.img-lg{
    height: 500px;
}

.px-40{
    padding-left: 100px;
    padding-right: 100px;
}

.bg-wallpaper{
    background-image: url(http://localhost:3000/static/media/background.cf1dbffb5080aae797b8.svg);
}

.gap-5{
    grid-gap: 50px;
    gap: 50px;
}

.text-bold{
    font-weight: bold;
}
.text-xl{
    font-size: x-large;
}

.mt-5{
    margin-top: 50px;
}   

.back-btn{
    margin-bottom: 30px;
    &:hover{
        cursor: pointer;
    }
}


.text-left{
    text-align: left;
}

.text-blue{
    color: #1e96ec;
}

.px-60{
padding-left: 60px;
padding-right: 60px;
}

.glass-container{
    background-color: rgba(255, 255, 255, 0.30); 
    -webkit-backdrop-filter: blur(5px); 
            backdrop-filter: blur(5px);
    margin-top: 20px;
    margin-bottom: 30px;
    border-radius: 25px;
    border: none;
}

.text-2xl{
    font-size: xx-large;
}

.w-400{
    width: 400px;
}

.p-10{
    padding: 50px;
}

.bg-off-white{
    background-color: #ebebeb;
}

.p-5{
    padding: 20px;
}

.text-xl{
    font-size: x-large;
}

.text-lg{
    font-size: large;
    margin-bottom: 0;
}

.top-0{
    top: 0;
}


.blog-writer input[type=checkbox]
{
  /* Double-sized Checkboxes */ /* IE */ /* FF *//* Safari and Chrome */ /* Opera */
  transform: scale(1.5);
  padding: 5px;
}


.mb-5{
    margin-bottom: 24px;
}
.rounded-lg{
    border-radius: 10px;
}

.bg-ig-blue{
    background-color: #213546;
}

.border-none{
    border: white solid;
}

.text-green-500{
    color: #10B981;
}

.text-red-500{
    color: #EF4444;
}
.post-position-container {
    background-color: #CDE1D6;
    width: 75%;
    padding: 2em 0;
    margin: 0 auto; /* Centers horizontally */
    display: flex; /* Enables flexbox */
    flex-direction: column; /* Ensures vertical stacking */
    align-items: center; /* Centers content horizontally */
    justify-content: center; /* Centers content vertically */
  }
  
  .post-position-header {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #333;
    margin-bottom: 1.5em;
    margin-top: 1%;
  }
  
  .highlight {
    color: #06ad0e;
  }
  
  .post-position-section {
    width: 80%;
    margin: 0 auto;
  }
  
  .post-position-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .post-position-form-group {
    margin-bottom: 1.5em;
    width: 100%;
  }
  
  .post-position-label {
    display: block;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0.5em;
  }
  
  .post-position-input,
  .post-position-textarea {
    width: 100%;
    padding: 0.5em;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .post-position-checkbox {
    margin-left: 0.5em;
  }
  
  .post-position-textarea {
    height: 160px;
    resize: none;
  }
  
  .post-position-form-inline {
    display: flex;
    grid-gap: 1.5em;
    gap: 1.5em;
    width: 100%;
  }
  
  .post-position-form-button {
    text-align: center;
    margin-top: 2em;
  }
  
  .post-position-button {
    background-color: #233242;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    padding: 0.8em 1.5em;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .post-position-button:hover {
    background-color: #1b2a37;
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #CDE1D6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loading-text {
    color: "black";
    font-size: 1.3em;
    font-weight: 600;
    margin-top: 20px;
  }

  .post-position-checkbox-label {
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    margin-top: 1em;
    color: #333;
  }
  
  .post-position-checkbox {
    margin-right: 0.5em;
    transform: scale(1.2); 
  }
  
  .post-position-checkbox-description {
    font-size: 12px;
    color: #555;
    margin-left: 1.8em; 
    margin-top: 5px;
  }
  
  @media (max-width: 768px) {
    .post-position-form-inline {
      flex-direction: column;
      grid-gap: 1em;
      gap: 1em;
    }
  }

  @media only screen and (max-width: 600px) {
    .post-position-section {
      width: 100%;
    }
  }
.post-script-container {
    font-family: Arial, sans-serif;
    margin: 20px auto;
    width: 90%; /* Adjust width to match screenshot */
    max-width: 1200px; /* Set a maximum width for responsiveness */
    background-color: #ffffff; /* White background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 20px 30px;
  }
  
  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .script-content {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
  
  .bucket {
    background-color: #f9f9f9;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .bucket-header {
    display: flex;
    font-size: 18px;
    font-weight: bold;
  }

  .remove-icon {
    background: none;
    border: none;
    color: #333;
    margin-left: auto; 
  }

  .add-question-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 40px;
    height: 40px;
    border: 2px solid #1daf5c; 
    border-radius: 50%;
    background-color: #ffffff;
    cursor: pointer;
  }
  
  .add-question-button .plus-icon {
    color: #1daf5c; /* Green text */
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
  }
  
  .add-question-button:hover {
    background-color: #eaf8ed; /* Light green background on hover */
  }
  
  .bucket-body {
    margin-top: 10px;
  }
  
  .bucket-prompt {
    font-size: 16px;
  }

  .bucket-name {
    display: inline-block;
    margin-left: 15px;
    border: 1px solid #000; /* Adjust the color as needed */
    border-radius: 8px; /* Rounded corners */
    padding: 4px 10px; /* Adjust padding for spacing */
    font-size: 14px; /* Font size as requested */
    font-weight: 500; /* Semi-bold to match the design */
  }
  
  .additional-questions {
  }
  
  .additional-question {
    display: flex;
    background-color: #f1f1f1;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
  }

  .question-text {
    margin-left: 20px;
  }

  .example {
    font-size: 12px; /* Adjusted font size for readability */
    color: #6c757d; /* Gray text color */
    margin-left: 30px;
  }
  
  .post-script-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .post-script-button {
    background-color: #213546;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .post-script-button:hover {
    background-color: #172633;
  }

  .edit-question {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    align-items: center;
  }
  
  .edit-input {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 60%;
  }
  
  .save-button,
  .cancel-button {
    padding: 5px 10px;
    font-size: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-button {
    background-color: #28a745;
    color: white;
  }
  
  .cancel-button {
    background-color: #dc3545;
    color: white;
  }

  .new-question {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }
  
  .new-question-input {
    flex: 1 1;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
.review-page {
    max-width: 85%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f7f6;
    border-radius: 10px;
  }
  
  .review-page h1 {
    text-align: center;
    font-size: 2em;
    color: #213546;
  }
  
  .review-page .highlight {
    color: #1daf5c;
  }
  
  .review-page .subheading {
    text-align: center;
    font-size: 1em;
    color: #666;
    margin-bottom: 30px;
  }
  
  .job-posting, .interview-questions {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .job-posting h2, .interview-questions h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .job-posting p {
    font-size: 1em;
    line-height: 1.6;
  }
  
  .inline-detail {
    margin-left: 20px;
  }
  
  .bucket {
    margin-bottom: 15px;
  }
  
  .bucket h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .example2 {
    font-size: 1em;
    margin-left: 10px;
    color: #333;
  }
  
  .general-question {
    background-color: #f9f9f9;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
    color: #333;
    margin-top: 10px;
  }
  
  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .post-position-button {
    background-color: #1daf5c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .post-position-button:hover {
    background-color: #17a456;
  }

  .job-description-content {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 1em;
    color: #333333;
    line-height: 1.8;
  }

  .edit-icon-container {
    float: right;
    cursor: pointer;
  }
  
  .edit-icon {
    width: 20px;
    height: 20px;
  }

.hero_title-2 {
    font-size: 40px; /* You may need to adjust this depending on the viewport */
    padding-left: 10%; /* Adjust this value based on your design requirements */
    padding-right: 10%; /* Adjust this value based on your design requirements */
    margin-top: 5%;
    font-weight: bold;
    font-weight: 600;
    margin-bottom: 20px; /* Space between title and subtitle */
    text-align: center;
  }

.profile-container {
    display: flex;
    align-items: center;
    margin: 20px 0;
    margin-top: 4%;
  }
  
  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #4caf50;
    margin-bottom: 10px;
  }
  
  .profile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .roi-img {
    width: 80%;
    height: 60%;
    object-fit: cover;
  }
  
  .profile-label {
    font-size: 1.2rem;
    font-weight: 500;
    color: #ccc;
    margin-left: 13px;
  }

  .hero_subtitle-2 {
    font-size: 16px;
    margin-bottom: 40px; /* Space between subtitle and button */
    text-align: center;
    color: #cdcdcd;
    font-weight: 400;
    width: 80%;
  }

  .challenge-section {
    width: 80%;
    margin: 50px auto;
    text-align: left;
    margin-top: 6%;
  }

  .challenge-section2 {
    width: 80%;
    margin: 30px auto;
    text-align: left;
    margin-top: 1%;
  }
  
  .section-title {
    color: #90ee90;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .section-divider {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.2);
    width: 100%;
    margin-bottom: 30px;
  }
  
  .section-content {
    color: #fff;
    font-size: 16px;
    line-height: 1.4;
    margin-left: 6%;
  }
  
  .challenge-description {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .challenge-list {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
  }
  
  .challenge-list li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
  }
  
  .challenge-list li:before {
    content: "\2022";
    position: absolute;
    left: 0;
    color: #fff;
    font-weight: bold;
  }
  
  .challenge-list strong {
    font-weight: bold;
    margin: 0 4px;
  }
  
  .challenge-conclusion {
    margin-top: 30px;
  }

  .result-subtitle {
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
    margin-bottom: 15px;
    color: #fff;
  }
  
  .result-item {
    margin-bottom: 30px;
  }
  
  .challenge-list li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
  }
  
  .challenge-list li span {
    margin-right: 4px;
  }
  
  .challenge-list li strong {
    margin: 0 4px;
  }
  
  @media only screen and (max-width: 650px) {
    .hero_subtitle-2 {
      width: 90%;
    }

    .roi-img {
      width: 90%;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    .section-content {
      margin-left: 0%;
    }

    .challenge-list li {
      display: block;
      line-height: 1.5;
    }
  
    .challenge-list li strong {
      display: inline-block;
    }
  }

  
